import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography, useMediaQuery } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import theme from 'src/constants/theme';
import {
  DOCUMENT_EXPIRED_ERROR_SUBTITLE,
  DOCUMENT_EXPIRED_ERROR_TITLE
} from 'src/modules/DocumentsPrivate/constants/errors';

import { StyledErrorWrapper } from './styles';

const DefaultExpiredDocumentPage = ({
  onBackClick
}: {
  onBackClick?: () => void;
}) => {
  const { t } = useTranslation();

  const isNotMobile = useMediaQuery(theme.breakpoints.up('mobile'));

  return (
    <StyledErrorWrapper>
      <Stack>
        <Typography variant="h1" fontWeight={700} textAlign="center">
          {t(DOCUMENT_EXPIRED_ERROR_TITLE)}
        </Typography>
        <Typography
          variant="h3"
          fontWeight={500}
          width={isNotMobile ? '55%' : '100%'}
          textAlign="center"
          style={{ alignSelf: 'center' }}
        >
          {t(DOCUMENT_EXPIRED_ERROR_SUBTITLE)}
        </Typography>
        {!!onBackClick && (
          <Button
            onClick={onBackClick}
            startIcon={<KeyboardArrowLeftIcon viewBox="6 0 24 24" />}
            color="primary"
          >
            {t('PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_GO_BACK_BUTTON')}
          </Button>
        )}
      </Stack>
    </StyledErrorWrapper>
  );
};

export default DefaultExpiredDocumentPage;
