import { useContext } from 'react';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { UserIdentityContext } from 'src/modules/UserIdentity/contexts/UserIdentityContext';
import getCatalogByBlockchainAddress from 'src/modules/DocumentsPrivate/api/getCatalogByBlockchainAddress';
import { decryptDocument } from 'src/modules/DocumentsPrivate/utils';
import hasFeature from 'src/lib/hasFeature';
import { FullIdentityLoadUserKeysResponse } from 'src/modules/UserIdentity/types/api';
import {
  DecodedDocument,
  PrivateDocumentCatalog
} from 'src/modules/DocumentsPrivate/types';
import { DocumentTypes } from 'src/modules/DocumentsPrivate/constants/document';
import { PrivateDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';
import { transformCatalogByEncryptedCekList } from 'src/modules/DocumentsPrivate/utils';

import useDocumentByBlockchainAddress from './useDocumentByBlockchainAddress';

const handleDecrypt = (
  keys: FullIdentityLoadUserKeysResponse,
  documentCatalog: PrivateDocumentCatalog,
  blockchainAddress: string,
  hashFromHashFromPassword: string
): Promise<DecodedDocument> => {
  if (hasFeature('mockEndpoints')) {
    return Promise.resolve({
      title: 'test-doc',
      documentData: new ArrayBuffer(1),
      documentType: DocumentTypes.PDF,
      legalValidityFinishDate: null,
      legalValidityStartDate: new Date(),
      fullCategory: 'Test-category',
      mainCategory: 'Test-category'
    });
  }

  return decryptDocument({
    userKeys: keys,
    documentCatalog,
    blockchainAddress,
    hashFromHashFromPassword
  });
};

export const useDownloadDocument = (
  options?: UseMutationOptions<
    string | null,
    AxiosError,
    { blockchainAddress: string; code?: string }
  >
) => {
  const { state } = useContext(UserIdentityContext);
  const { state: privateDocumentState } = useContext(PrivateDocumentContext);
  const getDocumentByBlockchainAddress = useDocumentByBlockchainAddress();

  return useMutation<
    string | null,
    AxiosError,
    { blockchainAddress: string; code?: string }
  >(async ({ blockchainAddress, code }) => {
    if (privateDocumentState && code) {
      const { data: catalog } = await getCatalogByBlockchainAddress(
        blockchainAddress
      );

      const document = await getDocumentByBlockchainAddress({
        blockchainAddress,
        catalog: transformCatalogByEncryptedCekList(catalog),
        authorizedCode: code
      });
      const blob = new Blob([new Uint8Array(document?.documentData).buffer], {
        type: `application/${document.documentType.toLowerCase()}`
      });

      const link = window.document.createElement('a');

      link.href = URL.createObjectURL(blob);
      link.download = document?.title;
      window.document.body.append(link);
      link.click();
      link.remove();

      setTimeout(() => URL.revokeObjectURL(link.href));

      return null;
    }

    const { data: documentCatalog } = await getCatalogByBlockchainAddress(
      blockchainAddress
    );

    if (!state.keys || !state.hashFromHashFromPassword) {
      return null;
    }

    const decryptedDocument = await handleDecrypt(
      state.keys,
      transformCatalogByEncryptedCekList(documentCatalog),
      blockchainAddress,
      state.hashFromHashFromPassword
    );
    const documentBlob = new Blob(
      [new Uint8Array(decryptedDocument?.documentData).buffer],
      {
        type: `application/${decryptedDocument.documentType.toLowerCase()}`
      }
    );

    const link = window.document.createElement('a');

    link.href = URL.createObjectURL(documentBlob);
    link.download = decryptedDocument.title;
    window.document.body.append(link);
    link.click();
    link.remove();

    setTimeout(() => URL.revokeObjectURL(link.href));

    return blockchainAddress;
  }, options);
};

export default useDownloadDocument;
