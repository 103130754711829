import AppConfiguration from 'src/types/appConfiguration';

import diplomaUatConfig from './diploma/uat';
import diplomaDemoConfig from './diploma/demo';
import diplomaBdsConfig from './diploma/bds';
import mvpStaging from './remoteSignature/uat';
import mvpDemo from './remoteSignature/demo';
import mvp2Demo from './tauron/mvp2.demo';
import sandbox_config from './remoteSignature/sandbox';
import TauronTDUat from './tauron/tauron.td.uat';
import TauronTDUat2 from './tauron/tauron.td.uat2';
import TAURON_TS_TSGZE from './tauron/tauron.ts.tsgze.uat';
import tergo from './clients/tergo';

export enum AppName {
  DIPLOMA_UAT = 'DIPLOMA_UAT',
  DIPLOMA_DEMO = 'DIPLOMA_DEMO',
  DIPLOMA_BDS = 'DIPLOMA_BDS',
  MVP_REMOTE_SIGNATURE_STAGING = 'MVP_REMOTE_SIGNATURE_STAGING',
  MVP_REMOTE_SIGNATURE_DEMO = 'MVP_REMOTE_SIGNATURE_DEMO',
  MVP_REMOTE_SIGNATURE_PROD = 'MVP_REMOTE_SIGNATURE_PROD',
  MVP2_DEMO = 'MVP2_DEMO',
  SANDBOX = 'SANDBOX',
  TAURON_TD_UAT = 'TAURON_TD_UAT',
  TAURON_TD_UAT_2 = 'TAURON_TD_UAT_2',
  TAURON_TS_TSGZE = 'TAURON_TS_TSGZE',
  TERGO = 'TERGO'
}

export const AppConfigMapping: {
  [k in AppName]: AppConfiguration;
} = {
  [AppName.DIPLOMA_UAT]: diplomaUatConfig,
  [AppName.DIPLOMA_DEMO]: diplomaDemoConfig,
  [AppName.DIPLOMA_BDS]: diplomaBdsConfig,
  [AppName.MVP_REMOTE_SIGNATURE_STAGING]: mvpStaging,
  [AppName.MVP_REMOTE_SIGNATURE_DEMO]: mvpDemo,
  [AppName.MVP_REMOTE_SIGNATURE_PROD]: mvpDemo,
  [AppName.SANDBOX]: sandbox_config,
  [AppName.MVP2_DEMO]: mvp2Demo,
  [AppName.TAURON_TD_UAT]: TauronTDUat,
  [AppName.TAURON_TD_UAT_2]: TauronTDUat2,
  [AppName.TAURON_TS_TSGZE]: TAURON_TS_TSGZE,
  [AppName.TERGO]: tergo
};

const appName = process.env.REACT_APP_CONFIG as AppName;
export const appConfig = AppConfigMapping[appName];
