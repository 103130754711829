import { useContext, useEffect } from 'react';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';

import TauronPrivateDocumentDetails from 'src/modules/DocumentsPrivate/components/documentDetailsStep/components/TauronPrivateDocumentDetails';
import routes from 'src/constants/routes';
import { PrivateDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';
import { DocumentContainer } from 'src/modules/DocumentsPrivate/components/documentContent';
import useQueryParam from 'src/hooks/userQueryParam';

const BlockchainAddressDetailsPrivatePage = () => {
  const navigate = useNavigate();
  const { address: blockchainAddress } = useParams<{ address: string }>();
  const sharedSecretType = useQueryParam('sst');
  const auth = useQueryParam('auth');
  const { state } = useContext(PrivateDocumentContext);

  useEffect(() => {
    if (!state.isLoading && !state.document) {
      if (
        !!state.auth &&
        !!state.sharedSecretType &&
        !!state.blockchainAddress
      ) {
        navigate({
          pathname: routes.documentAccessType,
          search: createSearchParams({
            blockchainAddress: state.blockchainAddress,
            sst: state.sharedSecretType,
            auth: state.auth
          }).toString()
        });
      } else if (!!blockchainAddress && !!sharedSecretType && !!auth) {
        navigate({
          pathname: routes.documentAccessType,
          search: createSearchParams({
            blockchainAddress: blockchainAddress,
            sst: sharedSecretType,
            auth: auth
          }).toString()
        });
      } else {
        navigate(routes.documentAccessTypeIncorrectQuery);
      }
    }
  }, [
    auth,
    blockchainAddress,
    navigate,
    sharedSecretType,
    state.auth,
    state.blockchainAddress,
    state.document,
    state.isLoading,
    state.sharedSecretType
  ]);

  return (
    <DocumentContainer>
      <TauronPrivateDocumentDetails
        document={state.document}
        catalog={state.catalog}
        blockchainAddress={state.blockchainAddress}
      />
    </DocumentContainer>
  );
};

export default BlockchainAddressDetailsPrivatePage;
