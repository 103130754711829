import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCallback, useContext } from 'react';
import { AxiosError } from 'axios';

import StepperControls from 'src/components/stepper/components/stepperControls';
import routes from 'src/constants/routes';
import getCatalogByBlockchainAddress from 'src/modules/DocumentsPrivate/api/getCatalogByBlockchainAddress';
import getPublicDocumentByBlockChainAddress from 'src/modules/DocumentsPrivate/api/getPublicDocumentByBlockchainAddress';
import { ERROR_BLOCKCHAIN_ADDRESS_NOT_FOUND } from 'src/modules/DocumentsPrivate/constants/errors';
import { PrivateDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';
import ApiError from 'src/lib/apiError';
import { UserIdentityContext } from 'src/modules/UserIdentity/contexts/UserIdentityContext';
import { appConfig } from 'src/constants/appConfig';
import { transformCatalogByEncryptedCekList } from 'src/modules/DocumentsPrivate/utils';

const DocumentVerificationPositiveStepperControls = () => {
  const { state: userIdentityState } = useContext(UserIdentityContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state, startLoading, setCatalog, setError, setAccessCode } =
    useContext(PrivateDocumentContext);

  const handleLoadDocumentCatalogClick = useCallback(() => {
    (async () => {
      if (!state.blockchainAddress) {
        return;
      }

      startLoading();
      setAccessCode('');

      if (!appConfig.diploma) {
        try {
          const { data: publicDocumentResponse } =
            await getPublicDocumentByBlockChainAddress({
              blockchainAddress: state.blockchainAddress,
              fetchSourceDocument: false
            });
          if (!!publicDocumentResponse?.documentBlockchainAddress) {
            navigate(
              routes.publicDocumentBlockchainAddress.replace(
                ':address',
                state.blockchainAddress
              )
            );
          }
        } catch (err) {}
      }

      try {
        const { data } = await getCatalogByBlockchainAddress(
          state.blockchainAddress
        );

        if (!data?.encryptedSensitiveContent) {
          setError(new ApiError(ERROR_BLOCKCHAIN_ADDRESS_NOT_FOUND));
          return;
        }
        setCatalog(transformCatalogByEncryptedCekList(data));

        if (!appConfig.diploma) {
          if (userIdentityState.status === 'UNAUTHORIZED') {
            navigate(
              `${routes.documentAccessType}?blockchainAddress=${state.blockchainAddress}`
            );
          }
          if (
            userIdentityState.status === 'AUTHORIZED' &&
            !!userIdentityState.uuid
          ) {
            navigate(
              routes.documentBlockchainAddress
                .replace(':uuid', userIdentityState.uuid)
                .replace(':address', state.blockchainAddress)
            );
          }
        } else {
          navigate(
            routes.verifyBlockchainAddressAccessCode.replace(
              ':address',
              state.blockchainAddress
            )
          );
        }
      } catch (err) {
        const error = err as AxiosError;
        setError(new ApiError(error.message));
      }
    })();
  }, [
    navigate,
    setAccessCode,
    setCatalog,
    setError,
    startLoading,
    state.blockchainAddress,
    userIdentityState.status,
    userIdentityState.uuid
  ]);

  return (
    <StepperControls>
      <Button onClick={() => navigate(routes.index)} color="secondary">
        {t('PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_CANCEL_BUTTON')}
      </Button>
      <LoadingButton
        onClick={handleLoadDocumentCatalogClick}
        loadingPosition="end"
        endIcon={<KeyboardArrowRightIcon viewBox="-6 0 24 24" />}
        variant="contained"
      >
        <span>
          {t('PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_DOWNLOAD_DOCUMENT_BUTTON')}
        </span>
      </LoadingButton>
    </StepperControls>
  );
};

export default DocumentVerificationPositiveStepperControls;
