import AppConfiguration from 'src/types/appConfiguration';

export default {
  reactAppApiBaseUrl: 'https://apidiplomareader.demo.tdm.bybillon.com',
  reactAppUserIdentityApiBaseUrl: 'https://apirt.demo.tdm.bybillon.com',
  reactAppNationalNodeApiBaseUrl: 'https://wk.demo.tdm.bybillon.com/',
  siteTitle: 'Trusted Document Register - Reading Platform',
  documentPrivate: true,
  documentPublic: false,
  documentVerification: true,
  documentRemoteSigning: false,
  userLogin: false,
  userRegistration: false,
  userResetPassword: false,
  mockEndpoints: false,
  diploma: true,
  envPrefix: 'DEMO'
} as AppConfiguration;
