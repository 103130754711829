import { Button, Divider, Typography } from '@mui/material';
import { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation, Trans } from 'react-i18next';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';

import FormTextField from 'src/components/form/formTextField';
import FormAlert from 'src/components/formAlert';
import useLoginUser from 'src/modules/UserIdentity/hooks/useLoginUser';
import routes from 'src/constants/routes';
import { UserIdentityContext } from 'src/modules/UserIdentity/contexts/UserIdentityContext';
import {
  ERROR_EMPTY_FIELD,
  ERROR_WRONG_EMAIL_INPUT
} from 'src/modules/UserIdentity/constants/errors';
import { EMAIL_REGEX } from 'src/modules/UserIdentity/constants/form';
import { StepperHeader } from 'src/components/stepper';
import PasswordField from 'src/components/form/passwordField';
import { PrivacyPolicy, TermsOfUse } from 'src/components/regulations';
import { RegulationsContainer } from 'src/components/regulationContainer';
import useQueryParam from 'src/hooks/userQueryParam';

import {
  StyledContainer,
  StyledContent,
  StyledDividerContainer,
  StyledFormContainer,
  StyledHeaderContainer,
  StyledRegisterContainer,
  StyledRegisterLink,
  StyledStepperControls
} from './styles';

type LoginForm = {
  email: string;
  password: string;
};

const schema = yup
  .object()
  .shape({
    email: yup
      .string()
      .matches(new RegExp(EMAIL_REGEX), { message: ERROR_WRONG_EMAIL_INPUT })
      .required(ERROR_EMPTY_FIELD),
    password: yup.string().required(ERROR_EMPTY_FIELD)
  })
  .required();

const AccessTypeLoginPage = () => {
  const navigate = useNavigate();
  const blockchainAddress = useQueryParam('blockchainAddress');
  const { t } = useTranslation();
  const formProps = useForm<LoginForm>({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });
  const { saveLogin } = useContext(UserIdentityContext);

  const { mutate, isLoading, error } = useLoginUser();
  const { register, handleSubmit } = formProps;

  const handleLoginClick = useCallback(
    (values: LoginForm) => {
      saveLogin(values.email, values.password);
      mutate({
        email: values.email?.trim(),
        password: values.password
      });
    },
    [mutate, saveLogin]
  );

  const handleBack = () => {
    navigate(
      blockchainAddress
        ? `${routes.documentAccessType}?blockchainAddress=${blockchainAddress}`
        : routes.documentAccessType
    );
  };

  return (
    <StyledContainer>
      <FormProvider {...formProps}>
        <form onSubmit={handleSubmit(handleLoginClick)}>
          <FormAlert error={error} />
          <StyledContent>
            <StyledHeaderContainer>
              <StepperHeader
                subtitleKey="LOG_IN_DESCRIPTION"
                titleKey="LOG_IN_TITLE"
              />
            </StyledHeaderContainer>
            <StyledFormContainer>
              <FormTextField
                trim
                fullWidth
                variant="outlined"
                label={t('LOG_IN_EMAIL')}
                InputProps={{ ...register('email') }}
              />
              <PasswordField
                fullWidth
                variant="outlined"
                type="password"
                helperText={
                  formProps.formState.errors.password?.message as string
                }
                label={t('LOG_IN_PASSWORD')}
                InputProps={{ ...register('password') }}
              />
            </StyledFormContainer>

            <StyledStepperControls>
              <Button
                onClick={handleBack}
                startIcon={<KeyboardArrowLeftIcon viewBox="6 0 24 24" />}
                color="secondary"
              >
                {t('PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_GO_BACK_BUTTON')}
              </Button>

              <LoadingButton
                type="submit"
                disabled={isLoading}
                loading={isLoading}
                variant="contained"
              >
                <span>{t('LOG_IN_LOG_IN_BUTTON')}</span>
              </LoadingButton>
            </StyledStepperControls>

            <StyledDividerContainer>
              <Divider />
              <StyledRegisterContainer>
                <StyledRegisterLink to={routes.documentAccessTypeRegister}>
                  <Typography variant="subtitle2">
                    <Trans>LOG_IN_REGISTRATION</Trans>
                  </Typography>
                </StyledRegisterLink>
              </StyledRegisterContainer>
            </StyledDividerContainer>
          </StyledContent>
        </form>
        <RegulationsContainer>
          <PrivacyPolicy /> <TermsOfUse />
        </RegulationsContainer>
      </FormProvider>
    </StyledContainer>
  );
};

export default AccessTypeLoginPage;
