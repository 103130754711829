import { useCallback } from 'react';

import createActiveDeliveryEvent from 'src/modules/Crypto/lib/activeDeliveryEvents/createActiveDeliveryEvent';
import {
  TokenData,
  useActiveDeliveryState
} from 'src/modules/DocumentsPrivate/components/documentSignatureForm/context/ActiveDeliveryStateProvider';
import useAddEvent from 'src/modules/DocumentsPrivate/hooks/useAddEvent';
import { GetPartnerEncryptionKeyResponse } from 'src/modules/DocumentsPrivate/types';

type useProcessDocumentProps = {
  uuid: string;
  partnerEncryptionKey?: GetPartnerEncryptionKeyResponse;
  publisherId: string;
  documentBase58Hash?: string;
  blockchainAddress?: string;
};

const EventTypes = {
  CONFIRMATION_OF_ACKNOWLEDGEMENT: 'CONFIRMATION_OF_ACKNOWLEDGEMENT',
  CONFIRMATION_OF_RECEIPT: 'CONFIRMATION_OF_RECEIPT'
};

const SUCCESS_STATUS = 'PUBLISHING-OK';

const useSendActiveDeliveryEvent = ({
  uuid,
  partnerEncryptionKey,
  publisherId,
  documentBase58Hash,
  blockchainAddress
}: useProcessDocumentProps) => {
  const {
    isDocumentReceived,
    isDocumentAcknowledged,
    confirmDocumentReceived,
    confirmDocumentAcknowledged,
    getConfirmationOfReceiptTokenData,
    getConfirmationOfAcknowledgementTokenData
  } = useActiveDeliveryState();

  const { mutateAsync: addEvent } = useAddEvent({
    onSuccess: (data, variables) => {
      const { status } = data;
      if (status === SUCCESS_STATUS) {
        if (
          variables.eventType === EventTypes.CONFIRMATION_OF_ACKNOWLEDGEMENT
        ) {
          confirmDocumentAcknowledged();
        }
        if (variables.eventType === EventTypes.CONFIRMATION_OF_RECEIPT) {
          confirmDocumentReceived();
        }
      } else {
        /*
        TODO HANDLE ALTERNATIVE STATUSES AFTER CONSULTING WITH PRODUCT
        Current behaviour is a placeholder in order to not block document flow
        */

        if (
          variables.eventType === EventTypes.CONFIRMATION_OF_ACKNOWLEDGEMENT
        ) {
          confirmDocumentAcknowledged();
        }
        if (variables.eventType === EventTypes.CONFIRMATION_OF_RECEIPT) {
          confirmDocumentReceived();
        }
      }
    },

    onError: (_, variables) => {
      /*
      TODO IMPLEMENT ERROR HANDLING AFTER CONSULTING WITH PRODUCT
      Current behaviour is a placeholder in order to not block document flow
      */
      if (variables.eventType === EventTypes.CONFIRMATION_OF_ACKNOWLEDGEMENT) {
        confirmDocumentAcknowledged();
      }
      if (variables.eventType === EventTypes.CONFIRMATION_OF_RECEIPT) {
        confirmDocumentReceived();
      }
    }
  });

  const createAndSendEvent = useCallback(
    async (
      tokenData: TokenData,
      eventType: string,
      blockchainAddress: string,
      partnerEncryptionKey: GetPartnerEncryptionKeyResponse
    ) => {
      const { tokenIdBase58, tokenBody } = tokenData;

      const encryptedSensitiveEventData = await createActiveDeliveryEvent({
        documentHash: documentBase58Hash,
        uuid,
        tokenIdBase58,
        partnerEncryptionKey
      });

      const oneYearFromNow = new Date();
      oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

      return addEvent({
        publisherId,
        signedEventToken: tokenBody,
        encryptedSensitiveEventData,
        retentionDate: oneYearFromNow.toISOString(),
        blockchainAddress,
        eventType
      });
    },
    [addEvent, documentBase58Hash, publisherId, uuid]
  );

  const sendAcknowledgementEvent = useCallback(() => {
    const tokenData = getConfirmationOfAcknowledgementTokenData();
    if (
      partnerEncryptionKey &&
      !isDocumentAcknowledged &&
      tokenData &&
      blockchainAddress
    ) {
      return createAndSendEvent(
        tokenData,
        EventTypes.CONFIRMATION_OF_ACKNOWLEDGEMENT,
        blockchainAddress,
        partnerEncryptionKey
      );
    }
    return null;
  }, [
    getConfirmationOfAcknowledgementTokenData,
    partnerEncryptionKey,
    isDocumentAcknowledged,
    blockchainAddress,
    createAndSendEvent
  ]);

  const sendReceiptEvent = useCallback(() => {
    const tokenData = getConfirmationOfReceiptTokenData();

    if (
      tokenData &&
      blockchainAddress &&
      partnerEncryptionKey &&
      !isDocumentReceived
    ) {
      return createAndSendEvent(
        tokenData,
        EventTypes.CONFIRMATION_OF_RECEIPT,
        blockchainAddress,
        partnerEncryptionKey
      );
    }
    return null;
  }, [
    getConfirmationOfReceiptTokenData,
    partnerEncryptionKey,
    isDocumentReceived,
    blockchainAddress,
    createAndSendEvent
  ]);

  return { sendAcknowledgementEvent, sendReceiptEvent };
};

export default useSendActiveDeliveryEvent;
