import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { decode } from 'base64-arraybuffer';

import { localeDateFormatter } from 'src/lib/formatters';
import { DetailsElementType } from 'src/modules/DocumentsPrivate/components/documentDetailsStep/components/tauronDocumentDetails/components/detailDataSection/DetailDataSection';
import usePublicDocument from 'src/modules/DocumentsPrivate/hooks/usePublicDocument';
import routes from 'src/constants/routes';
import { getFileSizeInKB } from 'src/modules/DocumentsPrivate/components/documentDetailsStep/utils';
import { getDocumentExpirationFlag } from 'src/modules/DocumentsPrivate/helpers/getDocumentCatalogExpiredErrorResponse';
import { DocumentErrorResponseType } from 'src/modules/DocumentsPrivate/types';

import TauronDocumentDetails from './tauronDocumentDetails';

type DocumentDetailsStepType = {
  blockchainAddress: string;
  token: string;
  sum: string;
};

const TauronPublicDocumentDetails = ({
  blockchainAddress,
  token,
  sum
}: DocumentDetailsStepType) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [documentBlob, setDocumentBlob] = useState<Blob | undefined | null>(
    null
  );
  const [documentTitle, setDocumentTitle] = useState<string>('');

  const {
    data: document,
    isLoading: isDocumentLoading,
    isError,
    error,
    failureCount
  } = usePublicDocument(blockchainAddress, token, sum, true);

  const isLoading = !document || isDocumentLoading;

  const isExpired = useMemo(() => {
    const errorData = error?.response?.data as DocumentErrorResponseType;
    if (isError && !!error) {
      return getDocumentExpirationFlag(errorData);
    }
    return false;
  }, [isError, error]);

  if (isError && !isExpired && failureCount > 2) {
    navigate(routes.publicDocumentIncorrectURL);
  }

  useEffect(() => {
    if (document?.sourceDocument && blockchainAddress) {
      setDocumentBlob(
        new Blob([new Uint8Array(decode(document?.sourceDocument)).buffer], {
          type: `application/pdf`
        })
      );
      setDocumentTitle(document?.title);
    }
  }, [document, blockchainAddress]);

  const detailsGroup: Array<DetailsElementType> = [
    {
      title: t('PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_PUBLICATION_CATEGORY'),
      value: document?.category
    },
    {
      title: t('PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_PUBLICATION_DATE'),
      value:
        document?.publicationDate &&
        localeDateFormatter(document.publicationDate)
    },
    {
      title: t('PRIVATE_DOCUMENT_PUBLISHED_BY'),
      value: document?.publisherName
    },
    {
      title: t('PRIVATE_DOCUMENT_SOURCE_DOCUMENT'),
      value: 'PDF'
    },
    {
      title: t('PRIVATE_DOCUMENT_FILE_SIZE'),
      value: `${getFileSizeInKB(documentBlob?.size)} KB`
    }
  ];

  if (!blockchainAddress || !sum || !token) {
    navigate(routes.publicDocumentIncorrectURL);
  }

  if (isExpired) {
    navigate(routes.publicDocumentExpired);
  }

  return (
    <TauronDocumentDetails
      isLoading={isLoading}
      detailsGroup={detailsGroup}
      documentTitle={documentTitle}
      documentBlob={documentBlob}
    />
  );
};

export default TauronPublicDocumentDetails;
