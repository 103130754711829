import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Typography, useMediaQuery, useTheme } from '@mui/material';

import { appConfig } from 'src/constants/appConfig';
import routes from 'src/constants/routes';
import tauronLogo from 'src/assets/tauron/logo.jpg';
import tauronLogoHorizontal from 'src/assets/tauron/logo-horizontal.png';
import billonLogo from 'src/assets/mvp2/billon-logo.png';
import billonLogoHorizontal from 'src/assets/billonLogo.svg';
import { ReactComponent as HelpIcon } from 'src/assets/tauron/help.svg';
import TauronWidthContainer from 'src/components/tauronWidthContainer';

import {
  StyledAppBar,
  StyledAnchor,
  StyledContent,
  StyleHelpBtn
} from './styles';

const AppBarTauron = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const isSquareLogo = useMediaQuery(theme.breakpoints.up(970));

  const { brandURL, mvp2 } = appConfig || {};

  const handleOpenSupportTab = () => {
    navigate(routes.help);
  };

  return (
    <StyledAppBar>
      <TauronWidthContainer>
        <StyledContent>
          <StyledAnchor
            href={brandURL || '/'}
            isSquareLogo={isSquareLogo}
            mvp2={mvp2}
          >
            {isSquareLogo ? (
              <img
                src={mvp2 ? billonLogo : tauronLogo}
                width={100}
                height={100}
                alt="brandLogo"
              />
            ) : (
              <img
                src={mvp2 ? billonLogoHorizontal : tauronLogoHorizontal}
                height={mvp2 ? 30 : 50}
                alt="brandLogo"
              />
            )}
          </StyledAnchor>
          <StyleHelpBtn
            variant="text"
            color="secondary"
            size="small"
            onClick={handleOpenSupportTab}
          >
            <HelpIcon />
            {'\u00A0\u00A0\u00A0'}
            <Typography
              sx={{ marginTop: isSquareLogo ? '4px' : '0px' }}
              variant="buttonMedium"
              fontWeight="200"
              fontSize="17px"
            >
              {t('HOME_HELP')}
            </Typography>
          </StyleHelpBtn>
        </StyledContent>
      </TauronWidthContainer>
    </StyledAppBar>
  );
};

export default AppBarTauron;
