import { createContext, PropsWithChildren, useMemo } from 'react';

import { appConfig } from 'src/constants/appConfig';
import useApiHealth from 'src/modules/DocumentsPrivate/hooks/useApiHealth';

type ApiCheckContextProps = {
  retryApiCheck: () => void;
  isErrorDisplayed: boolean;
  isLoading: boolean;
};

export const ApiCheckContext = createContext<ApiCheckContextProps>({
  retryApiCheck: () => undefined,
  isErrorDisplayed: false,
  isLoading: false
});

export const ApiCheckProvider = ({ children }: PropsWithChildren) => {
  const healthCheckConfig = !!appConfig?.healthCheck
    ? {
        retry: appConfig.healthCheck.maxRefetchCount,
        retryDelay: appConfig.healthCheck.interval * 1000,
        refetchOnWindowFocus: false,
        retryOnMount: false
      }
    : {
        retry: false
      };

  const { data, isError, isLoading, refetch } = useApiHealth(healthCheckConfig);

  const isErrorDisplayed = useMemo(
    () => isError || data?.status === 'error',
    [isError, data]
  );

  const contextValue = useMemo(
    () => ({
      retryApiCheck: refetch,
      isErrorDisplayed,
      isLoading
    }),
    [refetch, isErrorDisplayed, isLoading]
  );

  return (
    <ApiCheckContext.Provider value={contextValue}>
      {children}
    </ApiCheckContext.Provider>
  );
};
