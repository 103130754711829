import { Step, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DocumentActiveDeliveryForm from 'src/modules/DocumentsPrivate/components/documentDetailsStep/components/DocumentActiveDeliveryForm/DocumentActiveDeliveryForm';
import { useActiveDeliveryState } from 'src/modules/DocumentsPrivate/components/documentSignatureForm/context/ActiveDeliveryStateProvider';
import { AddEventResponseType } from 'src/modules/DocumentsPrivate/types';

import {
  StyledWrapper,
  StyledStepper,
  StyledStepConnector,
  StyledStepLabel,
  StyledAcceptIcon,
  StyledPendingIcon
} from './styles';

type DocumentActiveDeliveryStatusPropsType = {
  sendAcknowledgementEvent: () => Promise<AddEventResponseType> | null;
};

const DocumentActiveDeliveryStatus = ({
  sendAcknowledgementEvent
}: DocumentActiveDeliveryStatusPropsType) => {
  const {
    isDocumentAcknowledged,
    isConfirmationOfReceiptEnabled,
    isDocumentReceived,
    isConfirmationOfAcknowledgementEnabled
  } = useActiveDeliveryState();

  const { t } = useTranslation();

  const activeDeliveryDisabled = !(
    isConfirmationOfReceiptEnabled || isConfirmationOfAcknowledgementEnabled
  );

  if (activeDeliveryDisabled) {
    return null;
  }

  const bothConfirmationsEnabled =
    isConfirmationOfAcknowledgementEnabled && isConfirmationOfReceiptEnabled;

  return (
    <StyledWrapper>
      <Typography variant="subtitle1" fontWeight={600}>
        {t('PRIVATE_DOCUMENTS_DETAILS_ACTIVE_DELIVERY')}
      </Typography>
      <StyledStepper
        activeStep={bothConfirmationsEnabled ? 2 : 1}
        orientation="vertical"
        connector={<StyledStepConnector />}
      >
        {isConfirmationOfReceiptEnabled && (
          <Step key="confirmation_of_receipt_step">
            <StyledStepLabel
              StepIconComponent={
                isDocumentReceived ? StyledAcceptIcon : StyledPendingIcon
              }
            >
              <Typography variant="subtitle2" fontWeight={600}>
                {t('PRIVATE_DOCUMENTS_DETAILS_ACTIVE_DELIVERY_RECEIVED')}
              </Typography>
            </StyledStepLabel>
          </Step>
        )}
        {isConfirmationOfAcknowledgementEnabled && (
          <Step key="confirmation_of_acknowledgement_step">
            <StyledStepLabel
              StepIconComponent={
                isDocumentAcknowledged ? StyledAcceptIcon : StyledPendingIcon
              }
            >
              <Typography variant="subtitle2" fontWeight={600}>
                {t('PRIVATE_DOCUMENTS_DETAILS_ACTIVE_DELIVERY_ACKNOWLEDGED')}
              </Typography>
            </StyledStepLabel>
          </Step>
        )}
        {isConfirmationOfAcknowledgementEnabled && !isDocumentAcknowledged && (
          <DocumentActiveDeliveryForm
            sendAcknowledgementEvent={sendAcknowledgementEvent}
          />
        )}
      </StyledStepper>
    </StyledWrapper>
  );
};

export default DocumentActiveDeliveryStatus;
