import { useMemo, useEffect } from 'react';

import {
  DecodedDocument,
  PrivateDocumentCatalog,
  TokenEntry
} from 'src/modules/DocumentsPrivate/types';

type handleActiveDeliverySetupProps = {
  catalog: PrivateDocumentCatalog | undefined;
  document: DecodedDocument | undefined;
  confirmDocumentAcknowledged: () => void;
  confirmDocumentReceived: () => void;
  setActiveDeliveryTokens: (tokens: TokenEntry[] | undefined) => void;
};

const useHandleActiveDeliverySetup = ({
  catalog,
  document,
  confirmDocumentAcknowledged,
  confirmDocumentReceived,
  setActiveDeliveryTokens
}: handleActiveDeliverySetupProps) => {
  const confirmationOfAcknowledgement = useMemo(
    () => catalog?.CONFIRMATION_OF_ACKNOWLEDGEMENT || '',
    [catalog]
  );

  const confirmationOfReciept = useMemo(
    () => catalog?.CONFIRMATION_OF_RECEIPT || '',
    [catalog]
  );

  useEffect(() => {
    setActiveDeliveryTokens(document?.tokenData);
  }, [document, setActiveDeliveryTokens]);

  useEffect(() => {
    if (confirmationOfAcknowledgement) {
      confirmDocumentAcknowledged();
    }

    if (confirmationOfReciept) {
      confirmDocumentReceived();
    }
  }, [
    confirmDocumentAcknowledged,
    confirmDocumentReceived,
    confirmationOfAcknowledgement,
    confirmationOfReciept
  ]);
};

export default useHandleActiveDeliverySetup;
