import { useCallback, useContext } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { UseFormGetValues } from 'react-hook-form';

import hasFeature from 'src/lib/hasFeature';
import routes from 'src/constants/routes';
import ApiError from 'src/lib/apiError';
import { PrivateDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';
import useDocumentByBlockchainAddress from 'src/modules/DocumentsPrivate/hooks/useDocumentByBlockchainAddress';

interface SelectBlockchainAddressPasswordForm {
  blockchainAddressPassword: string;
}

export const useHandleDecryptDocument = (
  getValues: UseFormGetValues<SelectBlockchainAddressPasswordForm>
) => {
  const navigate = useNavigate();
  const { state, startLoading, setDocument, setError } = useContext(
    PrivateDocumentContext
  );
  const getDocumentByBlockchainAddress = useDocumentByBlockchainAddress();

  return useCallback(() => {
    const { blockchainAddressPassword } = getValues();

    (async () => {
      if (
        !blockchainAddressPassword ||
        !state.blockchainAddress ||
        !state.catalog
      ) {
        return;
      }

      if (state.error) {
        setError();
      }

      startLoading();

      let authorizedCode = blockchainAddressPassword;

      if (hasFeature('tauron') && state.auth) {
        authorizedCode += state.auth;
      }

      try {
        const document = await getDocumentByBlockchainAddress({
          blockchainAddress: state.blockchainAddress,
          catalog: state.catalog,
          authorizedCode: authorizedCode
        });

        setDocument(document);

        if (hasFeature('diploma')) {
          navigate(
            routes.diplomaBlockchainAddressDetails.replace(
              ':address',
              state.blockchainAddress
            )
          );
        } else {
          const destinationPathName = routes.blockchainAddressDetails.replace(
            ':address',
            state.blockchainAddress
          );
          navigate(
            hasFeature('tauron') &&
              !!state.blockchainAddress &&
              !!state.sharedSecretType &&
              !!state.auth
              ? {
                  pathname: destinationPathName,
                  search: createSearchParams({
                    sst: state.sharedSecretType,
                    auth: state.auth
                  }).toString()
                }
              : destinationPathName
          );
        }
      } catch (err) {
        setError(new ApiError('PRIVATE_DOCUMENT_UNEXPECTED_ERROR'));
      }
    })();
  }, [
    getDocumentByBlockchainAddress,
    getValues,
    navigate,
    setDocument,
    setError,
    startLoading,
    state.auth,
    state.blockchainAddress,
    state.catalog,
    state.error,
    state.sharedSecretType
  ]);
};
