export const ERROR_AUTHORIZATION_CODE_NOT_FOUND =
  'ERROR_AUTHORIZATION_CODE_NOT_FOUND';
export const ERROR_WRONG_AUTHORIZATION_CODE = 'ERROR_WRONG_AUTHORIZATION_CODE';

export const ERROR_BLOCKCHAIN_ADDRESS_NOT_FOUND =
  'ERROR_BLOCKCHAIN_ADDRESS_NOT_FOUND';

export const MORE_THAN_ONE_FULL_IDENTITY_FOUND =
  'MORE_THAN_ONE_FULL_IDENTITY_FOUND';

export const LOGIN_TWO_FACTOR_AUTHORIZATION = 'LOGIN_TWO_FACTOR_AUTHORIZATION';

export const ERROR_WRONG_ACCESS_CODE = 'ERROR_WRONG_ACCESS_CODE';

export const ERROR_WRONG_BLOCKCHAIN_ADDRESS_LENGTH =
  'ERROR_WRONG_BLOCKCHAIN_ADDRESS_LENGTH';

export const ERROR_INCORRECT_PESEL = 'ERROR_INCORRECT_PESEL';

export const SMS_VERIFICATION_CODE_LENGTH = 'SMS_VERIFICATION_CODE_LENGTH';

export const EXPIRED_DOCUMENT_ERROR_RESPONSE_MESSAGE = 'Document is expired';
export const EXPIRED_DOCUMENT_ERROR_RESPONSE_STATUS_CODE = 410;
export const DOCUMENT_EXPIRED_ERROR_TITLE = 'DOCUMENT_EXPIRED_ERROR_TITLE';
export const DOCUMENT_EXPIRED_ERROR_SUBTITLE =
  'DOCUMENT_EXPIRED_ERROR_SUBTITLE';
