import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

import { ApiCheckContext } from 'src/contexts/ApiCheckContext';

import { StyledReplayIcon } from './styles';

const RetryButton = () => {
  const { t } = useTranslation();
  const { isLoading, retryApiCheck } = useContext(ApiCheckContext);

  return (
    <LoadingButton
      onClick={retryApiCheck}
      loading={isLoading}
      startIcon={
        !isLoading && (
          <StyledReplayIcon
            style={{ width: '26px', height: '26px', margin: '-4px 0 0 0' }}
          />
        )
      }
      loadingPosition="start"
      variant="text"
      sx={{
        paddingLeft: isLoading ? '32px' : '8px',
        paddingRight: isLoading ? '22px' : '8px'
      }}
    >
      <span> {t('TRY_AGAIN')}</span>
    </LoadingButton>
  );
};

export default RetryButton;
