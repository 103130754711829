import { useContext } from 'react';
import { RouterProvider } from 'react-router-dom';

import { ApiCheckContext } from 'src/contexts/ApiCheckContext';
import routers from 'src/lib/routers';

const ApiCheck = () => {
  const { router, routerWithLoader, routerWithServiceOutage } = routers;

  const { isErrorDisplayed, isLoading } = useContext(ApiCheckContext);

  if (isLoading) {
    return <RouterProvider router={routerWithLoader} />;
  }

  if (isErrorDisplayed) {
    return <RouterProvider router={routerWithServiceOutage} />;
  }

  return <RouterProvider router={router} />;
};

export default ApiCheck;
