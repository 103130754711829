import ASN1 from '@lapo/asn1js';

import Hex from './Hex';
import Base64 from './Base64';

const decodeText = val => {
  const reHex = /^\s*(?:[0-9A-Fa-f][0-9A-Fa-f]\s*)+$/;
  const hexDecoderInput = reHex.test(val);
  const der = hexDecoderInput ? Hex.decode(val) : Base64.unarmor(val);
  const asn1 = ASN1.decode(der);
  const firstString = asn1.toHexString().substring(14, 46); // 16 byte x 2 = 32
  const secondString = asn1.toHexString().substring(50, 114); // 32 byte x 2 = 64
  return {
    protocolVersionNumber: 1,
    firstString: firstString,
    secondString: secondString
  };
};

export default decodeText;
