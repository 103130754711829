import { Buffer } from 'buffer';

import moment from 'moment';

import decryptCEK from 'src/modules/Crypto/lib/decryptCEK';
import hexToUInt8Array from 'src/modules/Crypto/lib/hexToUInt8Array';
import base64ToHex from 'src/modules/Crypto/lib/base64ToHex';
import convertCEK from 'src/modules/Crypto/lib/convertCEK';
import decryptUserAOL from 'src/modules/Crypto/lib/decryptUserAOL';
import mergeKeys from 'src/modules/Crypto/lib/mergeKeys';
import makeSensitiveDataBufferAndCek from 'src/modules/Crypto/lib/makeSensitiveDataBufferAndCek';
import encryptPassword from 'src/modules/Crypto/lib/encryptPassword';
import decryptDocumentContent from 'src/modules/Crypto/lib/decryptDocumentContent';
import hasFeature from 'src/lib/hasFeature';
import routes from 'src/constants/routes';

import {
  DecodedDocument,
  DecryptDocumentMeta,
  DecryptDocumentType,
  DecryptedDocument,
  DocumentSettings,
  EncryptedCekDLTIdentity,
  GetPrivateDocumentCatalogResponse,
  PrivateDocumentCatalog
} from './types';
import getDocumentByBlockChainAddress from './api/getDocumentByBlockchainAddress';
import { DocumentTypes } from './constants/document';

const maxFileStringRegex = /(\d+) (MB|KB)/;

export const parseMaxFileSizeString = (input: string): DocumentSettings => {
  const parse = input.match(maxFileStringRegex);

  if (!parse) {
    throw new Error('WRONG_MAX_FILE_SIZE_STRING_INPUT');
  }

  return {
    maxFileSize: Number(parse[1]),
    maxFileSizeUnit: parse[2] as DocumentSettings['maxFileSizeUnit']
  };
};

export const decryptDocumentMeta = async ({
  mergedKeys,
  userKeys,
  document,
  hashFromHashFromPassword
}: DecryptDocumentMeta): Promise<DecryptedDocument> => {
  const decryptedPassword = Buffer.from(
    userKeys.semiFullIdentityKeysList[0].encryptedPassword ||
      hashFromHashFromPassword
  );

  const cek = await decryptCEK(
    Buffer.from(document.encryptedCek, 'base64'),
    mergedKeys,
    hexToUInt8Array(
      base64ToHex(userKeys.semiFullIdentityKeysList[0].encryptedKek)
    ),
    // @ts-ignore
    String.fromCharCode.apply(null, new Uint8Array(decryptedPassword))
  );

  const convertedCEK = convertCEK(Buffer.from(cek));

  if (!!convertedCEK.key) {
    const buffer = Buffer.from(document.encryptedDocumentData, 'base64');
    const sensitiveData = await decryptUserAOL(convertedCEK, buffer);
    return sensitiveData as DecryptedDocument;
  }
  return {
    blockchainAddress: document?.blockchainAddress,
    title: null,
    categoryHash: null,
    decryptionFailed: true
  } as unknown as DecryptedDocument;
};

export const decryptDocument = async ({
  userKeys,
  documentCatalog,
  blockchainAddress,
  hashFromHashFromPassword
}: DecryptDocumentType): Promise<DecodedDocument> => {
  const key275 = userKeys.semiFullIdentityKeysList[0];
  const key25 = userKeys.semiFullIdentityKeysList[1];
  const decryptedPassword = Buffer.from(
    userKeys.semiFullIdentityKeysList[0].encryptedPassword ||
      hashFromHashFromPassword
  );

  const mergedKeysBuffer = await mergeKeys(
    key25.encryptedKek,
    key25.encryptedPrivateKeys,
    key25.encryptedPassword,
    hexToUInt8Array(base64ToHex(key275.encryptedPrivateKeys)),
    hexToUInt8Array(base64ToHex(key275.encryptedKek)),
    decryptedPassword
  );
  const encryptedPasswordBuffer = await encryptPassword(
    userKeys.semiFullIdentityKeysList[0].encryptedPassword ||
      hashFromHashFromPassword,
    hexToUInt8Array(base64ToHex(key275.encryptedKek))
  );

  const response = await makeSensitiveDataBufferAndCek({
    encryptedPassword: encryptedPasswordBuffer,
    encryptedCekDLTIdentity: documentCatalog
      .encryptedCekList[0] as EncryptedCekDLTIdentity,
    encryptedSensitiveContent: documentCatalog.encryptedSensitiveContent,
    privateKeys: mergedKeysBuffer,
    kek: hexToUInt8Array(base64ToHex(key275.encryptedKek))
  });

  const { data: encodedDocument } = await getDocumentByBlockChainAddress({
    catalogOfDocumentContent: response.sensitiveData.catalogue,
    blockchainAddress
  });
  const encryptedDocumentBuffer = Buffer.from(
    encodedDocument.encryptedDocumentContent,
    'base64'
  );

  const { convertedCEK } = response;
  const documentContent = await decryptDocumentContent(
    convertedCEK,
    encryptedDocumentBuffer
  );

  return {
    documentType: DocumentTypes.PDF,
    documentData: documentContent,
    title: response.sensitiveData.title,
    fullCategory: response.sensitiveData.fullCategory,
    mainCategory: response.sensitiveData.mainCategory,
    legalValidityFinishDate: response.sensitiveData.legalValidityFinishDate,
    legalValidityStartDate: response.sensitiveData.legalValidityStartDate,
    tokenData: response.sensitiveData.tokenData
  };
};

export const parseDateFilter = (date: string) =>
  !!date ? moment(date).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ') : '';

export const getPrivateDocumentIndexRoute = () => {
  if (hasFeature('userLogin')) {
    return routes.documentAccessType;
  } else if (hasFeature('diploma')) {
    return routes.diplomaBlockchainIndex;
  }
  return routes.blockchainIndex;
};

export const transformCatalogByEncryptedCekList = (
  response: GetPrivateDocumentCatalogResponse
) => {
  const { encryptedCekList, ...rest } = response || {};

  if (Array.isArray(encryptedCekList)) {
    return response as PrivateDocumentCatalog;
  }

  const { ceks } = encryptedCekList || {};
  return {
    ...rest,
    encryptedCekList: [...ceks]
  } as PrivateDocumentCatalog;
};
