import { createContext, useCallback, useMemo, useReducer } from 'react';
import { Outlet } from 'react-router-dom';

import {
  PrivateDocumentsStepperState,
  PrivateDocumentsContextProps,
  PrivateDocumentsWizardActions,
  PrivateDocumentCatalog,
  DecodedDocument,
  VerifyDocumentResponse
} from 'src/modules/DocumentsPrivate/types';
import privateDocumentWizardReducer from 'src/modules/DocumentsPrivate/reducers';
import ApiError from 'src/lib/apiError';

export const PrivateDocumentContext =
  createContext<PrivateDocumentsContextProps>({
    state: {
      isLoading: false
    },
    setDocument: () => {},
    setError: () => {},
    setBlockchainAddress: () => {},
    setAccessCode: () => {},
    setCatalog: () => {},
    startLoading: () => {},
    setVerificationResult: () => {},
    setDocumentNumber: () => {},
    setSharedSecretType: () => {},
    setAuth: () => {},
    stopLoading: () => {},
    setInfo: () => {}
  });

const usePrivateDocumentWizardState = (
  initialState: PrivateDocumentsStepperState
) =>
  useReducer<
    (
      prevState: PrivateDocumentsStepperState,
      action: PrivateDocumentsWizardActions
    ) => PrivateDocumentsStepperState
  >(privateDocumentWizardReducer, initialState);

export const PrivateDocumentContextProvider = () => {
  const [current, dispatch] = usePrivateDocumentWizardState({
    isLoading: false
  });

  const setBlockchainAddress = useCallback(
    (blockchainAddress: string) => {
      dispatch({
        ACTION: 'SET_BLOCKCHAIN_ADDRESS',
        blockchainAddress
      });
    },
    [dispatch]
  );

  const setVerificationResult = useCallback(
    (verificationResult: VerifyDocumentResponse | null) => {
      dispatch({
        ACTION: 'SET_VERIFICATION_RESULT',
        verificationResult
      });
    },
    [dispatch]
  );

  const setError = useCallback(
    (error?: ApiError) => {
      dispatch({
        ACTION: 'SET_ERROR',
        error
      });
    },
    [dispatch]
  );

  const setAccessCode = useCallback(
    (accessCode: string) => {
      dispatch({
        ACTION: 'SET_ACCESS_CODE',
        accessCode
      });
    },
    [dispatch]
  );

  const setDocument = useCallback(
    (document: DecodedDocument) => {
      dispatch({
        ACTION: 'SET_DOCUMENT',
        document
      });
    },
    [dispatch]
  );

  const startLoading = useCallback(
    () =>
      dispatch({
        ACTION: 'LOADING'
      }),
    [dispatch]
  );

  const setCatalog = useCallback(
    (catalog: PrivateDocumentCatalog) => {
      dispatch({
        ACTION: 'SET_CATALOG',
        catalog
      });
    },
    [dispatch]
  );

  const setDocumentNumber = useCallback(
    (documentNumber: string) => {
      dispatch({
        ACTION: 'SET_DOCUMENT_NUMBER',
        documentNumber
      });
    },
    [dispatch]
  );

  const setSharedSecretType = useCallback(
    (sharedSecretType: string) => {
      dispatch({
        ACTION: 'SET_SHARED_SECRET_TYPE',
        sharedSecretType
      });
    },
    [dispatch]
  );

  const setAuth = useCallback(
    (auth: string) => {
      dispatch({
        ACTION: 'SET_AUTH',
        auth
      });
    },
    [dispatch]
  );

  const stopLoading = useCallback(
    () =>
      dispatch({
        ACTION: 'STOP_LOADING'
      }),
    [dispatch]
  );

  const setInfo = useCallback(
    () =>
      dispatch({
        ACTION: 'SET_INFO'
      }),
    [dispatch]
  );

  const state = useMemo(
    () => ({
      isLoading: current.isLoading,
      blockchainAddress: current.blockchainAddress,
      accessCode: current.accessCode,
      document: current.document,
      catalog: current.catalog,
      verificationResult: current.verificationResult,
      error: current.error,
      documentNumber: current.documentNumber,
      sharedSecretType: current.sharedSecretType,
      auth: current.auth,
      info: current.info
    }),
    [
      current.accessCode,
      current.blockchainAddress,
      current.catalog,
      current.document,
      current.isLoading,
      current.verificationResult,
      current.error,
      current.documentNumber,
      current.sharedSecretType,
      current.auth,
      current.info
    ]
  );

  return (
    <PrivateDocumentContext.Provider
      value={{
        setVerificationResult,
        setBlockchainAddress,
        setAccessCode,
        setCatalog,
        state,
        startLoading,
        setError,
        setDocument,
        setDocumentNumber,
        setSharedSecretType,
        setAuth,
        stopLoading,
        setInfo
      }}
    >
      <Outlet />
    </PrivateDocumentContext.Provider>
  );
};
