import AppConfiguration from 'src/types/appConfiguration';

export default {
  reactAppApiBaseUrl: 'https://apimvp2reader.demo.tdm.bybillon.com',
  reactAppUserIdentityApiBaseUrl: 'https://apirt.demo.tdm.bybillon.com',
  reactAppNationalNodeApiBaseUrl: 'https://wk.demo.tdm.bybillon.com/',
  siteTitle: 'SITE_TITLE_BILLON_UNIFIED_BLOCKCHAIN',
  documentPrivate: true,
  documentPublic: false,
  documentVerification: false,
  documentRemoteSigning: true,
  userLogin: true,
  userRegistration: true,
  userResetPassword: false,
  mockEndpoints: false,
  diploma: false,
  documentPrivateCategoryFilter: false,
  documentPrivateCategories: true,
  envPrefix: 'DEMO',
  tauron: true,
  mvp2: true,
  brandURL: 'https://billongroup.com/',
  brandContactURL: 'https://billongroup.com/contact-us',
  fontFamily: '"Titillium Web",Arial,sans-serif',
  copyright: 'Copyright ® 2024 Billon',
  blockchainDocumentPasswordLength: 8,
  documentNumberAlgorithm: {
    PESEL: {
      fullLength: 11,
      codeLength: 5,
      randomIndices: true
    },
    NIP: {
      codeLength: 10,
      dashesCount: 3
    },
    POSTAL_CODE: {
      codeLength: 6,
      dashesCount: 1
    }
  },
  initLanguage: 'pl'
} as AppConfiguration;
