import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  Link,
  useTheme,
  useMediaQuery
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import { appConfig } from 'src/constants/appConfig';
import FeedbackImage from 'src/assets/tauron/feedback.png';

import {
  StyledErrorWrapper,
  StyledPrimaryEmphasis,
  StyledButton,
  StyledImage,
  StyledFeedbackIcon
} from './styles';

const TauronFeedbackPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();

  const handleBackClick = () => navigate(-1);

  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
  const isMobile = useMediaQuery(theme.breakpoints.only('mobile'));

  return (
    <StyledErrorWrapper>
      <Box
        sx={{
          maxWidth: isMobile ? '100%' : '50%',
          marginTop: isMobile ? '0' : '4rem'
        }}
      >
        <Typography
          variant="h2"
          fontWeight={800}
          fontSize="4.5rem"
          paddingBottom={isMobile ? '1rem' : '1.5rem'}
        >
          {t('FEEDBACK_TITLE_PART_ONE')}{' '}
          <StyledPrimaryEmphasis>
            {t('FEEDBACK_TITLE_PART_TWO')}?
          </StyledPrimaryEmphasis>
        </Typography>
        <Typography
          variant={isMobile ? 'h6' : 'h2'}
          fontWeight={500}
          marginBottom={isMobile ? '1rem' : '3rem'}
        >
          {t('FEEDBACK_SUBTITLE')}
        </Typography>

        <Box
          sx={{
            marginBottom: isMobile ? '0' : '1rem'
          }}
        >
          <Link
            href={appConfig.brandContactURL || ''}
            target="_blank"
            rel="noopener noreferrer"
            underline="none"
          >
            <StyledButton
              startIcon={<StyledFeedbackIcon />}
              variant="outlined"
              size={isDesktop ? 'large' : 'small'}
              sx={{
                padding: isDesktop ? '2rem' : '1rem'
              }}
            >
              <Typography
                variant={isDesktop ? 'h4' : 'h6'}
                fontWeight={600}
                sx={{ marginLeft: isMobile ? '0' : '15px' }}
              >
                {t('FEEDBACK_FORM_BUTTON_DESCRIPTION')}
              </Typography>
            </StyledButton>
          </Link>
        </Box>

        <Button
          onClick={handleBackClick}
          startIcon={<KeyboardArrowLeftIcon />}
          color="primary"
          sx={{
            marginTop: isMobile ? '0' : '4rem'
          }}
        >
          {t('RETURN_BUTTON')}
        </Button>
      </Box>

      <StyledImage
        src={FeedbackImage}
        alt={t('FEEDBACK_FORM_BUTTON_DESCRIPTION') || ''}
      />
    </StyledErrorWrapper>
  );
};

export default TauronFeedbackPage;
