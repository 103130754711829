import AppConfiguration from 'src/types/appConfiguration';

export default {
  reactAppApiBaseUrl: 'https://apidiplomareader.uat.tdm.bybillon.com',
  reactAppUserIdentityApiBaseUrl: 'https://apirt.uat.tdm.bybillon.com',
  reactAppNationalNodeApiBaseUrl: 'https://wk.uat.tdm.bybillon.com/',
  siteTitle: 'SITE_TITLE_BILLON_UNIFIED_BLOCKCHAIN',
  documentPrivate: true,
  documentPublic: false,
  documentVerification: true,
  documentRemoteSigning: false,
  userLogin: false,
  userRegistration: false,
  userResetPassword: false,
  mockEndpoints: false,
  diploma: true,
  envPrefix: 'DEV'
} as AppConfiguration;
