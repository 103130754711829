import { useContext } from 'react';

import extractKey from 'src/modules/Crypto/lib/extractKey';
import SignFormComponent from 'src/modules/DocumentsPrivate/components/documentSignatureForm/SignFormComponent';
import { UserIdentityContext } from 'src/modules/UserIdentity/contexts/UserIdentityContext';
import { AgreementStatusEnum } from 'src/modules/DocumentsPrivate/constants/document';
import generateSignature from 'src/modules/Crypto/lib/signDocument';
import {
  DecodedDocument,
  GetPartnerEncryptionKeyResponse
} from 'src/modules/DocumentsPrivate/types';
import SignatureProgressStatus from 'src/modules/DocumentsPrivate/components/documentSignatureForm/components/SignatureInProgress/SignatureProgressStatus';
import getDeviceIp from 'src/modules/DocumentsPrivate/api/getDeviceIp';

import AuthorizationRequest from './components/AuthorizationRequest';
import SignatureHistory from './components/SignatureHistory/SignatureHistory';
import { SignatureProgressModesEnum } from './constants';

type PropTypes = {
  agreementStatus: AgreementStatusEnum | null;
  blockchainAddress: string;
  publisherId: string;
  documentTitle: string;
  document: DecodedDocument;
  documentBase58Hash?: string;
  partnerEncryptionKey?: GetPartnerEncryptionKeyResponse | undefined;
  handleDownloadDocument: () => void;
  sendAcknowledgementEvent: () => void;
};
const SignFormWrapper = ({
  agreementStatus,
  blockchainAddress,
  publisherId,
  documentTitle,
  document,
  partnerEncryptionKey,
  sendAcknowledgementEvent
}: PropTypes) => {
  const { state: userIdentityState } = useContext(UserIdentityContext);

  const signDocument = async (blockchainAddress: string, action: string) => {
    const { user } = userIdentityState;
    const { documentData } = document;
    const publisherPublicKey = partnerEncryptionKey?.keyInfo?.keyData;
    const keyId = partnerEncryptionKey?.keyInfo?.keyId;
    const keyNumber = partnerEncryptionKey?.keyInfo?.keyNumber;
    const {
      data: { deviceIP }
    } = await getDeviceIp();
    const { keys, mergedKeys } = userIdentityState;

    const signatureKey = keys
      ? await extractKey(
          mergedKeys,
          keys.semiFullIdentityKeysList[0].encryptedKek,
          keys.semiFullIdentityKeysList[0].encryptedPassword ||
            userIdentityState.hashFromHashFromPassword
        )
      : null;

    return await generateSignature({
      blockchainAddress,
      document: documentData,
      user,
      publisherId,
      action,
      publisherPublicKey,
      keyId,
      keyNumber,
      signatureKey,
      deviceIp: deviceIP
    });
  };

  const handleSignDocument = (action: string) =>
    signDocument(blockchainAddress as string, action);

  const isAuthorized = userIdentityState.status === 'AUTHORIZED';
  const isAgreementPending =
    agreementStatus === AgreementStatusEnum.WAITING_FOR_RECEIVER;
  const isAgreementResolved =
    agreementStatus === AgreementStatusEnum.REJECTED ||
    agreementStatus === AgreementStatusEnum.ACCEPTED;
  if (!isAuthorized) {
    return <AuthorizationRequest />;
  }

  if (
    agreementStatus === AgreementStatusEnum.REJECTED_WAITING_FOR_PUBLISHING ||
    agreementStatus === AgreementStatusEnum.REJECTED_DEPRECATED
  ) {
    return (
      <SignatureProgressStatus
        blockchainAddress={blockchainAddress}
        mode={SignatureProgressModesEnum.REJECTED}
      />
    );
  }

  if (
    agreementStatus === AgreementStatusEnum.ACCEPTED_WAITING_FOR_PUBLISHING ||
    agreementStatus === AgreementStatusEnum.ACCEPTED_DEPRECATED
  ) {
    return (
      <SignatureProgressStatus
        blockchainAddress={blockchainAddress}
        mode={SignatureProgressModesEnum.ACCEPTED}
      />
    );
  }

  return (
    <>
      {isAgreementPending && (
        <SignFormComponent
          blockchainAddress={blockchainAddress}
          handleSignDocument={handleSignDocument}
          sendAcknowledgementEvent={sendAcknowledgementEvent}
        />
      )}
      {isAgreementResolved && (
        <SignatureHistory documentTitle={documentTitle} />
      )}
    </>
  );
};

export default SignFormWrapper;
