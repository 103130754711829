import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import ImageInvalidURL from 'src/assets/tauron/URL-invalid.png';
import {
  DOCUMENT_EXPIRED_ERROR_TITLE,
  DOCUMENT_EXPIRED_ERROR_SUBTITLE
} from 'src/modules/DocumentsPrivate/constants/errors';

import {
  StyledErrorWrapper,
  StyledImageWrapper,
  StyledImage,
  StyledTextWrapper,
  StyledPrimaryEmphasis
} from './styles';

const TauronExpiredDocumentPage = () => {
  const { t } = useTranslation();
  return (
    <StyledErrorWrapper>
      <StyledImageWrapper>
        <StyledImage
          src={ImageInvalidURL}
          alt={`${t(DOCUMENT_EXPIRED_ERROR_TITLE)} ${t(
            DOCUMENT_EXPIRED_ERROR_SUBTITLE
          )}`}
        />
      </StyledImageWrapper>

      <StyledTextWrapper>
        <Typography variant="h1" fontWeight={800} textAlign="center">
          <StyledPrimaryEmphasis>
            {t(DOCUMENT_EXPIRED_ERROR_TITLE)}
          </StyledPrimaryEmphasis>
        </Typography>
        <Typography
          variant="h4"
          fontWeight={500}
          width="100%"
          textAlign="center"
        >
          {t(DOCUMENT_EXPIRED_ERROR_SUBTITLE)}
        </Typography>
      </StyledTextWrapper>
    </StyledErrorWrapper>
  );
};

export default TauronExpiredDocumentPage;
