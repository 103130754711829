import { useTranslation } from 'react-i18next';
import {
  Typography,
  SelectChangeEvent,
  useMediaQuery,
  Tab
} from '@mui/material';
import {
  useCallback,
  useEffect,
  useState,
  SyntheticEvent,
  useMemo,
  useRef,
  useContext
} from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { TabContext } from '@mui/lab';

import { ChainLoader } from 'src/components/loader';
import theme from 'src/constants/theme';
import hasFeature from 'src/lib/hasFeature';
import DocumentActiveDeliveryStatus from 'src/modules/DocumentsPrivate/components/documentDetailsStep/components/DocumentActiveDeliveryState/DocumentActiveDeliveryStatus';
import DocumentScaleControll from 'src/modules/DocumentsPrivate/components/documentDetailsStep/components/DocumentControllsComponent';
import DownlaodButton from 'src/modules/DocumentsPrivate/components/documentDetailsStep/components/DownlaodButton';
import useHandleActiveDeliverySetup from 'src/modules/DocumentsPrivate/components/documentDetailsStep/hooks/useHandleActiveDeliverySetup';
import useSendActiveDeliveryEvent from 'src/modules/DocumentsPrivate/hooks/useSendActiveDeliveryEvent';
import { useActiveDeliveryState } from 'src/modules/DocumentsPrivate/components/documentSignatureForm/context/ActiveDeliveryStateProvider';
import { SignatureFormProvider } from 'src/modules/DocumentsPrivate/components/documentSignatureForm/context/SignatureFormProvider';
import usePartnerEncryptionKey from 'src/modules/DocumentsPrivate/hooks/usePartnerEncryptionKey';
import {
  DecodedDocument,
  PrivateDocumentCatalog
} from 'src/modules/DocumentsPrivate/types';
import DocumentSignatureForm from 'src/modules/DocumentsPrivate/components/documentSignatureForm';
import DocumentHashTabs from 'src/modules/DocumentsPrivate/components/documentHashTabs';
import useDocumentPublisher from 'src/modules/DocumentsPrivate/hooks/useDocumentPublisher';
import usePostVerifyDocumentByBlockchainAddress from 'src/modules/DocumentsPrivate/hooks/usePostVerifyDocumentByBlockchainAddress';
import { AgreementStatusEnum } from 'src/modules/DocumentsPrivate/constants/document';
import DocumentHistory from 'src/modules/DocumentsPrivate/components/documentHistory';
import { DocumentHistoryProvider } from 'src/modules/DocumentsPrivate/contexts/DocumentHistoryContext';
import { UserIdentityContext } from 'src/modules/UserIdentity/contexts/UserIdentityContext';

import { handleOnScrollDebounced } from './utils';
import {
  StyledContentWrapper,
  StyledDivider,
  StyledDocumentDetailsHeader,
  StyledDocumentDetailsWrapper,
  StyledDocumentInformation,
  StyledDocumentInformations,
  StyledDocumentInformationTitle,
  StyledDocumentInformationValue,
  StyledDocumentWrapper,
  StyledHeaderRightSide,
  StyledLoader,
  StyledPreviewContainer,
  StyledRelativeContainer,
  StyledTabList,
  StyledTabPanel,
  StyledTabWrapper
} from './styles';

import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

enum TabName {
  DETAILS = 'details',
  HISTORY = 'history',
  SIGNING = 'signing',
  PREVIEW = 'preview'
}

type DocumentDetailsStepType = {
  document?: DecodedDocument;
  catalog?: PrivateDocumentCatalog;
  blockchainAddress?: string;
};

const DocumentDetailsStep = ({
  document,
  catalog,
  blockchainAddress
}: DocumentDetailsStepType) => {
  const inputRef = useRef<HTMLInputElement>();
  const { t } = useTranslation();
  const [documentBlob, setDocumentBlob] = useState<Blob | null>(null);
  const [documentTitle, setDocumentTitle] = useState<string>('');
  const [tabValue, setTabValue] = useState<TabName>(TabName.DETAILS);
  const [pagesTotalNumber, setPagesTotalNumber] = useState<number>(0);
  const [documentScale, setDocumentScale] = useState<number>(1.0);
  const [agreementStatus, setAgreementStatus] =
    useState<AgreementStatusEnum | null>(null);

  const {
    data: verificationData,
    isSuccess: isVerificationSuccess,
    isLoading: isVeryifyBlockchainAddress
  } = usePostVerifyDocumentByBlockchainAddress(
    { blockchainAddress },
    {
      enabled: !!blockchainAddress,
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  const displaySeparateViewerTab = useMediaQuery(
    theme.breakpoints.up('desktop')
  );

  const { state: userIdentityState } = useContext(UserIdentityContext);

  const {
    setActiveDeliveryTokens,
    isDocumentAcknowledged,
    isConfirmationOfReceiptEnabled,
    isDocumentReceived,
    isConfirmationOfAcknowledgementEnabled,
    confirmDocumentReceived,
    confirmDocumentAcknowledged
  } = useActiveDeliveryState();

  useHandleActiveDeliverySetup({
    catalog,
    document,
    confirmDocumentReceived,
    confirmDocumentAcknowledged,
    setActiveDeliveryTokens
  });

  const downscaleDocument = useMediaQuery(theme.breakpoints.down('tablet'));
  const onPageChange = useCallback((page: number | null) => {
    if (page === null) {
      return;
    }

    if (inputRef.current) {
      inputRef.current.value = page.toString();
    }
  }, []);
  const { data: publisher, isLoading: isLoadingPublisher } =
    useDocumentPublisher(
      {
        publisherId: verificationData?.publisherId || ''
      },
      {
        retry: false,
        enabled: isVerificationSuccess,
        refetchOnWindowFocus: false
      }
    );

  const publicationDate = useMemo(
    () => catalog?.documentInfo.documentBlockchainData?.publicationDate || '',
    [catalog]
  );

  const legalValidityStartDate = useMemo(
    () => document?.legalValidityStartDate || '',
    [document]
  );

  const publisherId = useMemo(
    () => catalog?.documentInfo.documentBlockchainData?.publisherId || '',
    [catalog]
  );

  useEffect(() => {
    if (document?.documentData && blockchainAddress) {
      setDocumentBlob(
        new Blob([new Uint8Array(document?.documentData).buffer], {
          type: `application/${document.documentType.toLowerCase()}`
        })
      );
      setDocumentTitle(document?.title);
    }
  }, [document, blockchainAddress]);

  useEffect(() => {
    const agreementStatus = catalog?.signingStatus;
    if (agreementStatus) {
      setAgreementStatus(agreementStatus);
      setTabValue(TabName.SIGNING);
    }
    return () => {
      setAgreementStatus(null);
      setTabValue(TabName.DETAILS);
    };
  }, [catalog]);

  useEffect(() => {
    if (downscaleDocument) {
      setDocumentScale(0.5);
    }
  }, [downscaleDocument]);

  useEffect(() => {
    if (displaySeparateViewerTab && tabValue === TabName.PREVIEW) {
      setTabValue(TabName.DETAILS);
    }
  }, [displaySeparateViewerTab, tabValue]);

  const handleDownloadClick = useCallback(() => {
    if (!documentBlob) {
      return;
    }

    const link = window.document.createElement('a');

    link.href = URL.createObjectURL(documentBlob);
    link.download = documentTitle;
    window.document.body.append(link);
    link.click();
    link.remove();

    setTimeout(() => URL.revokeObjectURL(link.href));
  }, [documentBlob, documentTitle]);

  const handleDocumentSuccessLoad = useCallback(
    ({ numPages }: { numPages: number }) => setPagesTotalNumber(numPages),
    []
  );

  const handleScaleChange = useCallback(
    (event: SelectChangeEvent<number>) =>
      setDocumentScale(event.target.value as number),
    []
  );

  const handleTabChange = useCallback(
    (_event: SyntheticEvent, newValue: TabName) => setTabValue(newValue),
    []
  );
  const isLoading =
    !document || !catalog || isVeryifyBlockchainAddress || isLoadingPublisher;

  const isDocumentSignable = agreementStatus !== null;

  const { data: partnerEncryptionKey } = usePartnerEncryptionKey(publisherId, {
    enabled:
      !!publisherId &&
      (isDocumentSignable ||
        isConfirmationOfReceiptEnabled ||
        isConfirmationOfAcknowledgementEnabled)
  });

  const { sendReceiptEvent, sendAcknowledgementEvent } =
    useSendActiveDeliveryEvent({
      uuid: userIdentityState.uuid as string,
      partnerEncryptionKey,
      publisherId,
      documentBase58Hash: blockchainAddress,
      blockchainAddress
    });

  useEffect(() => {
    if (
      isConfirmationOfReceiptEnabled &&
      !isDocumentReceived &&
      partnerEncryptionKey
    ) {
      sendReceiptEvent();
    }
  }, [
    isConfirmationOfReceiptEnabled,
    isDocumentReceived,
    sendReceiptEvent,
    partnerEncryptionKey
  ]);

  if (isLoading) {
    return (
      <StyledLoader sx={{ marginTop: theme.spacing(12) }}>
        <ChainLoader />
      </StyledLoader>
    );
  }

  return (
    <>
      <DocumentHistoryProvider
        blockchainAddress={blockchainAddress}
        publisherId={publisherId}
      >
        <StyledDocumentDetailsHeader>
          <Typography maxWidth="50%" noWrap variant="h6" fontWeight={600}>
            {documentTitle}
          </Typography>
          <StyledHeaderRightSide>
            {displaySeparateViewerTab && (
              <DocumentScaleControll
                t={t('PRIVATE_DOCUMENT_PREVIEW_PAGE')}
                inputRef={inputRef}
                max={pagesTotalNumber}
                value={documentScale}
                handleScaleChange={handleScaleChange}
              />
            )}
            <DownlaodButton
              disabled={
                isConfirmationOfAcknowledgementEnabled &&
                !isDocumentAcknowledged
              }
              onClick={handleDownloadClick}
            />
          </StyledHeaderRightSide>
        </StyledDocumentDetailsHeader>
        <SignatureFormProvider>
          <StyledContentWrapper>
            <StyledTabWrapper>
              <StyledDocumentDetailsWrapper>
                <TabContext value={tabValue}>
                  <StyledTabList onChange={handleTabChange}>
                    {!displaySeparateViewerTab && (
                      <Tab
                        label={t('PRIVATE_DOCUMENTS_LIST_PREVIEW_COLUMN')}
                        value={TabName.PREVIEW}
                      />
                    )}
                    {isDocumentSignable && (
                      <Tab
                        label={t('PRIVATE_DOCUMENTS_DETAILS_SIGNING')}
                        value={TabName.SIGNING}
                      />
                    )}
                    <Tab
                      label={t('PRIVATE_DOCUMENT_DETAILS')}
                      value={TabName.DETAILS}
                    />
                    {!isDocumentSignable && (
                      <Tab
                        label={t('PRIVATE_DOCUMENT_DETAILS_DOCUMENT_HISTORY')}
                        value={TabName.HISTORY}
                      />
                    )}
                  </StyledTabList>
                  <StyledDivider />
                  <StyledTabPanel value={TabName.SIGNING}>
                    {document && blockchainAddress && (
                      <>
                        <DocumentActiveDeliveryStatus
                          sendAcknowledgementEvent={sendAcknowledgementEvent}
                        />
                        <DocumentSignatureForm
                          document={document}
                          documentTitle={documentTitle}
                          agreementStatus={agreementStatus}
                          blockchainAddress={blockchainAddress}
                          publisherId={publisherId}
                          documentBase58Hash={verificationData?.base58}
                          partnerEncryptionKey={partnerEncryptionKey}
                          handleDownloadDocument={handleDownloadClick}
                          sendAcknowledgementEvent={sendAcknowledgementEvent}
                        />
                      </>
                    )}
                  </StyledTabPanel>
                  <StyledTabPanel value={TabName.DETAILS}>
                    {!isDocumentSignable && (
                      <DocumentActiveDeliveryStatus
                        sendAcknowledgementEvent={sendAcknowledgementEvent}
                      />
                    )}
                    <Typography
                      variant="subtitle1"
                      marginBottom="27px"
                      fontWeight={600}
                      sx={{ overflowWrap: 'break-word' }}
                    >
                      {documentTitle}
                    </Typography>
                    {isVerificationSuccess && (
                      <DocumentHashTabs document={verificationData} />
                    )}
                    <StyledDocumentInformations>
                      <StyledDocumentInformation>
                        <StyledDocumentInformationTitle
                          variant="body2"
                          fontWeight={600}
                        >
                          {`${t(
                            'PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_PUBLICATION_DATE'
                          )}: `}
                        </StyledDocumentInformationTitle>
                        <StyledDocumentInformationValue variant="body2">
                          {new Date(publicationDate).toLocaleString()}
                        </StyledDocumentInformationValue>
                      </StyledDocumentInformation>
                      {hasFeature('diploma') && legalValidityStartDate && (
                        <StyledDocumentInformation>
                          <StyledDocumentInformationTitle
                            variant="body2"
                            fontWeight={600}
                          >
                            {`${t(
                              'PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_DATE_OF_ISSUE'
                            )} `}
                          </StyledDocumentInformationTitle>
                          <StyledDocumentInformationValue variant="body2">
                            {new Date(
                              legalValidityStartDate
                            ).toLocaleDateString()}
                          </StyledDocumentInformationValue>
                        </StyledDocumentInformation>
                      )}
                      <StyledDocumentInformation>
                        <StyledDocumentInformationTitle
                          variant="body2"
                          fontWeight={600}
                        >
                          {`${t(
                            'PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_PUBLICATION_CATEGORY'
                          )}: `}
                        </StyledDocumentInformationTitle>
                        <StyledDocumentInformationValue variant="body2">
                          {document?.mainCategory}
                        </StyledDocumentInformationValue>
                      </StyledDocumentInformation>
                      <StyledDocumentInformation>
                        <StyledDocumentInformationTitle
                          variant="body2"
                          fontWeight={600}
                        >
                          {`${t(
                            'PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_PUBLISHER'
                          )}: `}
                        </StyledDocumentInformationTitle>
                        <StyledDocumentInformationValue variant="body2">
                          {publisher?.name}
                        </StyledDocumentInformationValue>
                      </StyledDocumentInformation>
                    </StyledDocumentInformations>
                  </StyledTabPanel>
                  <StyledTabPanel value={TabName.HISTORY}>
                    <DocumentHistory />
                  </StyledTabPanel>
                  <StyledTabPanel sx={{ marginTop: 0 }} value={TabName.PREVIEW}>
                    <StyledPreviewContainer>
                      <DocumentScaleControll
                        t={t('PRIVATE_DOCUMENT_PREVIEW_PAGE')}
                        inputRef={inputRef}
                        max={pagesTotalNumber}
                        value={documentScale}
                        handleScaleChange={handleScaleChange}
                      />
                      <StyledRelativeContainer>
                        <StyledDocumentWrapper
                          id="pdf-preview"
                          onScroll={() => handleOnScrollDebounced(onPageChange)}
                        >
                          {documentBlob && (
                            <Document
                              file={documentBlob}
                              onLoadSuccess={handleDocumentSuccessLoad}
                            >
                              {[...Array(pagesTotalNumber)].map(
                                (_el, index) => (
                                  <Page
                                    key={`page-${index}`}
                                    pageIndex={index}
                                    scale={documentScale}
                                  />
                                )
                              )}
                            </Document>
                          )}
                        </StyledDocumentWrapper>
                      </StyledRelativeContainer>
                    </StyledPreviewContainer>
                  </StyledTabPanel>
                </TabContext>
              </StyledDocumentDetailsWrapper>
            </StyledTabWrapper>
            {displaySeparateViewerTab && (
              <StyledRelativeContainer>
                <StyledDocumentWrapper
                  id="pdf-preview"
                  onScroll={() => handleOnScrollDebounced(onPageChange)}
                >
                  {documentBlob && (
                    <Document
                      file={documentBlob}
                      onLoadSuccess={handleDocumentSuccessLoad}
                    >
                      {[...Array(pagesTotalNumber)].map((_el, index) => (
                        <Page
                          key={`page-${index}`}
                          pageIndex={index}
                          scale={documentScale}
                        />
                      ))}
                    </Document>
                  )}
                </StyledDocumentWrapper>
              </StyledRelativeContainer>
            )}
          </StyledContentWrapper>
        </SignatureFormProvider>
      </DocumentHistoryProvider>
    </>
  );
};

export default DocumentDetailsStep;
