import { LoadingButton } from '@mui/lab';
import { styled, Checkbox } from '@mui/material';

export const StyledFormContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.custom.secondary[50],
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  marginBottom: theme.spacing(4)
}));

export const StyledCheckBoxContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  marginTop: theme.spacing(2)
}));

export const StyledButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end'
}));

export const StyledButton = styled(LoadingButton)(({ theme }) => ({
  [theme.breakpoints.down('desktop')]: {
    ...theme.typography.buttonMedium
  }
}));

export const StyledCheckbox = styled(Checkbox)(() => ({
  paddingLeft: 0
}));
