import { KeyboardArrowRight } from '@mui/icons-material';
import { useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  StyledCheckBoxContainer,
  StyledFormContainer,
  StyledButtonContainer,
  StyledButton,
  StyledCheckbox
} from 'src/modules/DocumentsPrivate/components/documentDetailsStep/components/DocumentActiveDeliveryForm/styles';
import { AddEventResponseType } from 'src/modules/DocumentsPrivate/types';

type DocumentActiveDeliveryFormPropsType = {
  sendAcknowledgementEvent: () => Promise<AddEventResponseType> | null;
};

const DocumentActiveDeliveryForm = ({
  sendAcknowledgementEvent
}: DocumentActiveDeliveryFormPropsType) => {
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleActiveDeliveryEvent = () => {
    const result = sendAcknowledgementEvent();
    if (result) {
      setLoading(true);
      result.finally(() => setLoading(false));
    }
  };

  const { t } = useTranslation();

  return (
    <>
      <StyledFormContainer>
        <Typography variant="subtitle1" fontWeight={600}>
          {t(
            'PRIVATE_DOCUMENTS_DETAILS_SIGNING_CONFIRMATION_OF_ACKNOWLEDGEMENT'
          )}
        </Typography>
        <StyledCheckBoxContainer>
          <StyledCheckbox
            checked={checked}
            onClick={() => setChecked(currentCheck => !currentCheck)}
          />
          <Typography variant="body2" fontWeight={400}>
            {t(
              'PRIVATE_DOCUMENTS_DETAILS_SIGNING_CONFIRMATION_OF_ACKNOWLEDGEMENT_CHECKBOX'
            )}
          </Typography>
        </StyledCheckBoxContainer>
        <StyledButtonContainer>
          <StyledButton
            disabled={!checked}
            loading={loading}
            variant="contained"
            onClick={handleActiveDeliveryEvent}
            endIcon={<KeyboardArrowRight />}
          >
            {t(
              'PRIVATE_DOCUMENTS_DETAILS_SIGNING_CONFIRMATION_OF_ACKNOWLEDGEMENT_DOWNLOAD_BUTTON'
            )}
          </StyledButton>
        </StyledButtonContainer>
      </StyledFormContainer>
    </>
  );
};

export default DocumentActiveDeliveryForm;
