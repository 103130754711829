import { Button, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DownloadIcon } from 'src/assets/fileDownload.svg';

type DownloadButtonPropsType = {
  disabled: boolean;
  onClick: () => void;
};

const DownloadButton = ({ disabled, onClick }: DownloadButtonPropsType) => {
  const { t } = useTranslation();

  const title = disabled
    ? t('PRIVATE_DOCUMENTS_DETAILS_ACTIVE_DOWNLOAD_TOOLTIP')
    : '';

  return (
    <Tooltip title={title}>
      <div>
        {/* mui things, tooltip won't work on disabled button without wrapper*/}
        <Button
          disabled={disabled}
          variant="contained"
          onClick={onClick}
          startIcon={<DownloadIcon />}
        >
          {t('PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_DOWNLOAD_DOCUMENT_BUTTON')}
        </Button>
      </div>
    </Tooltip>
  );
};

export default DownloadButton;
