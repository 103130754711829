import { appConfig } from 'src/constants/appConfig';

export const INTERVAL_THRESHOLD_MS = 2000;

export const SMS_CODE_THRESHOLD_MS = 1000 * 60 * 3; // 3 minutes

export default INTERVAL_THRESHOLD_MS;

export const USER_IDENTITY_API_URL =
  appConfig.reactAppUserIdentityApiBaseUrl as string;

if (!USER_IDENTITY_API_URL) {
  throw new Error('USER_IDENTITY_API_URL is not set');
}

export const NATIONAL_NODE_API_URL =
  appConfig.reactAppNationalNodeApiBaseUrl as string;

if (!NATIONAL_NODE_API_URL) {
  throw new Error('NATIONAL_NODE_API_URL is not set');
}
