const routes = {
  index: '/',
  documentAccessType: '/user-identity',
  documentAccessTypeLogin: '/user-identity/login',
  documentAccessType2Sms: '/user-identity/sms',
  documentAccessTypeLoginSelectDocument: '/user-identity/login-select-document',
  documentAccessTypeRegister: '/user-identity/register',
  documentAccessTypeNationalNodeLaunch: '/user-identity/national-node/launch',
  documentAccessTypeNationalNodeSuccess: '/user-identity/national-node/success',
  documentAccessTypeNationalNodeFailure: '/user-identity/national-node/failure',
  documentAccessTypeRegisterDetails: '/user-identity/register-details',
  documentAccessTypeVerify: '/user-identity/verify/:processId',
  documentAccessTypePassword: '/user-identity/password/:processId',
  documentAccessTypeSuccess: '/user-identity/finish',
  documentAccessTypeFailure: '/user-identity/failure',
  documentAccessTypeIncorrectQuery: '/user-identity/incorrectQuery',
  documentAccessUserIndex: '/user-identity/:uuid/documents',
  documentBlockchainAddress: '/user-identity/:uuid/documents/:address',
  blockchainIndex: '/blockchain-address',
  blockchainAddress: '/blockchain-address/:address',
  blockchainAddressDetails: '/blockchain-address/:address/details',
  publicDocumentList: '/public-documents',
  diplomaBlockchainIndex: '/document/diploma',
  diplomaBlockchainAddress: '/document/diploma/:address',
  diplomaBlockchainAddressDetails: '/document/diploma/:address/details',
  diplomaIncorrectURL: '/document/diploma/incorrectQuery',
  publicDocument: '/document',
  publicDocumentBlockchainAddress: '/document/:address',
  publicDocumentBlockchainAddressAlternative: '/document/p/:address',
  publicDocumentIncorrectURL: '/document/incorrectQuery',
  publicDocumentExpired: '/document/expired',
  verifyIndex: '/verify',
  verifyBlockchainAddressResult: '/verify/result',
  verifyBlockchainAddressAccessCode: '/verify/:address/access-code',
  verifyBlockchainAddressDownload: '/verify/:address/download',
  help: '/help',
  cipherTest: '/cipher-test'
};

export default routes;
