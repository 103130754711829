import { Grid, Typography } from '@mui/material';
import { DefaultTFuncReturn, TFuncReturn } from 'i18next';

import { StyledDocumentDetailsIcon } from './styles';

const DetailsBadge = ({
  t
}: {
  t: TFuncReturn<'translation', string, DefaultTFuncReturn, undefined>;
}) => (
  <Typography variant="h6" fontWeight={600}>
    <Grid container direction="row" alignItems="center">
      <StyledDocumentDetailsIcon
        style={{
          marginRight: '15px'
        }}
      />
      {t}
    </Grid>
  </Typography>
);

export default DetailsBadge;
