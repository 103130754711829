import { Box } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, createSearchParams } from 'react-router-dom';

import useQueryParam from 'src/hooks/userQueryParam';
import { StepperContent } from 'src/components/stepper';
import SetDocumentNumberStep from 'src/modules/DocumentsPrivate/components/setDocumentNumberStep';
import { TauronPrivateDocumentWizardSteps } from 'src/modules/DocumentsPrivate/constants/stepper';
import { PrivateDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';
import StepperControls from 'src/components/stepper/components/stepperControls';
import { StepperContainer } from 'src/components/stepper';
import FormAlert from 'src/components/formAlert';
import routes from 'src/constants/routes';
import useSendTauronSmsCode from 'src/modules/UserIdentity/hooks/useSendTauronSmsCode';
import {
  SstEnum,
  parseSstToRequestData
} from 'src/modules/UserIdentity/constants/identityParams';
import useDocumentCatalog from 'src/modules/DocumentsPrivate/hooks/useDocumentCatalog';
import { SmsStatusEnum } from 'src/modules/UserIdentity/types/tauronSms';
import FormNotifier from 'src/components/formAlert/FormNotifier';
import SubmitButton from 'src/modules/DocumentsPrivate/components/documentAccess/components/submitButton';
import ResponsiveStepper from 'src/modules/DocumentsPrivate/components/documentAccess/components/responsiveStepper';
import SetOptDocumentNumberStep from 'src/modules/DocumentsPrivate/components/setOptDocumentNumberStep';
import { appConfig } from 'src/constants/appConfig';
import { parseDocumentNumber } from 'src/modules/UserIdentity/utils/documentNumberUtils/parseDocumentNumber';
import { useResponseStatusNotification } from 'src/modules/DocumentsPrivate/hooks/useResponseStatusNotification';
import { getDocumentCatalogExpiredErrorResponse } from 'src/modules/DocumentsPrivate/helpers/getDocumentCatalogExpiredErrorResponse';

import { useSchema } from './schema';

interface SelectDocumentNumberForm {
  documentNumber: string;
}

const BlockchainAddressDocumentNumberPage = () => {
  const navigate = useNavigate();
  const {
    state,
    setDocumentNumber,
    setBlockchainAddress,
    setCatalog,
    startLoading,
    setError,
    setInfo,
    setSharedSecretType,
    setAuth,
    stopLoading
  } = useContext(PrivateDocumentContext);
  const { setStatusNotification } = useResponseStatusNotification();

  const blockchainAddress = useQueryParam('blockchainAddress');
  const sharedSecretType = useQueryParam('sst');
  const auth = useQueryParam('auth');

  const documentNumberType = useMemo(
    () => parseSstToRequestData(sharedSecretType),
    [sharedSecretType]
  );

  const schema = useSchema(documentNumberType);
  const formProps = useForm<SelectDocumentNumberForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      documentNumber: state.documentNumber
    }
  });

  const { mutate: sendSmsCode } = useSendTauronSmsCode({
    onSuccess: ({ status, blockedUntil }) => {
      stopLoading();

      setStatusNotification(status, blockedUntil);

      if ([SmsStatusEnum.SUCCESS].includes(status)) {
        if (blockchainAddress && sharedSecretType && auth) {
          const params = { blockchainAddress, sst: sharedSecretType, auth };

          navigate({
            pathname: routes.documentAccessType2Sms,
            search: createSearchParams(params).toString()
          });
        }
        if (blockchainAddress && !sharedSecretType && !auth) {
          navigate({
            pathname: routes.documentAccessType2Sms,
            search: createSearchParams({ blockchainAddress }).toString()
          });
        }
      }
    },
    onError: () => {
      stopLoading();
    }
  });

  const handleSubmitClick = useCallback(() => {
    const { documentNumber } = formProps.getValues();

    if (!documentNumber) {
      return;
    }

    if (state.error) {
      setError();
    }

    setDocumentNumber(documentNumber);

    if (blockchainAddress) {
      setBlockchainAddress(blockchainAddress);
      if (sharedSecretType && auth) {
        sendSmsCode({
          blockchainAddress,
          type: documentNumberType,
          indexCharacterMap: parseDocumentNumber(
            documentNumber,
            sharedSecretType
          )
        });
        startLoading();
      }
    }
  }, [
    auth,
    blockchainAddress,
    documentNumberType,
    formProps,
    sendSmsCode,
    setBlockchainAddress,
    setDocumentNumber,
    setError,
    sharedSecretType,
    startLoading,
    state.error
  ]);

  useEffect(() => {
    setError();
  }, [setError]);

  useEffect(() => {
    setInfo();
  }, [setInfo]);

  useDocumentCatalog(blockchainAddress as string, {
    onSuccess: data => {
      if (auth) {
        setAuth(auth);
      }
      if (sharedSecretType) {
        setSharedSecretType(sharedSecretType);
      }
      if (blockchainAddress) {
        setBlockchainAddress(blockchainAddress);
        setCatalog(data);
      }
    },
    onError: err => {
      setError(getDocumentCatalogExpiredErrorResponse(err));
    },
    enabled: !state.blockchainAddress
  });

  useEffect(() => {
    if (!blockchainAddress || !sharedSecretType || !auth) {
      navigate(routes.documentAccessTypeIncorrectQuery);
    }
  }, [blockchainAddress, sharedSecretType, auth, navigate]);

  return (
    <FormProvider {...formProps}>
      <form onSubmit={formProps.handleSubmit(handleSubmitClick)}>
        <FormAlert error={state.error} />
        <StepperContainer>
          <ResponsiveStepper
            documentNumberType={documentNumberType}
            activeStep={TauronPrivateDocumentWizardSteps.DOCUMENT_NUMBER}
            orientation="vertical"
          />
          <StepperContent>
            <Box>
              <ResponsiveStepper
                documentNumberType={documentNumberType}
                activeStep={TauronPrivateDocumentWizardSteps.DOCUMENT_NUMBER}
                orientation="horizontal"
              />
              {sharedSecretType === SstEnum.P ? (
                <SetOptDocumentNumberStep
                  name="documentNumber"
                  orderNumber="1"
                  documentNumberType={documentNumberType}
                  infoToolTipTitle={
                    appConfig.documentNumberAlgorithm?.PESEL?.randomIndices
                      ? 'PRIVATE_DOCUMENT_PESEL_FIVE_RANDOM_CHARS'
                      : 'PRIVATE_DOCUMENT_PESEL_FIVE_LAST_CHARS'
                  }
                  onlyDigits
                  fullLength={
                    appConfig.documentNumberAlgorithm?.PESEL.fullLength ?? 11
                  }
                  codeLength={
                    appConfig.documentNumberAlgorithm?.PESEL.codeLength ?? 5
                  }
                  randomIndices={
                    appConfig.documentNumberAlgorithm?.PESEL.randomIndices ??
                    false
                  }
                />
              ) : (
                <SetDocumentNumberStep
                  orderNumber="1"
                  documentNumberType={documentNumberType}
                />
              )}
            </Box>
            <StepperControls>
              <SubmitButton
                loading={state.isLoading}
                disabled={state.isLoading}
              />
            </StepperControls>
            <FormNotifier info={state.info} severity="info" />
          </StepperContent>
        </StepperContainer>
      </form>
    </FormProvider>
  );
};

export default BlockchainAddressDocumentNumberPage;
