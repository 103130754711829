import { CheckCircle } from '@mui/icons-material';
import { StepConnector, StepLabel, Stepper, styled } from '@mui/material';

export const StyledWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column'
});

export const StyledStepper = styled(Stepper)(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  padding: 0,
  margin: theme.spacing(4, 0, 6, 0)
}));

export const StyledStepConnector = styled(StepConnector)({
  '& .MuiStepConnector-lineVertical': {
    height: '32px'
  }
});

export const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  '& .MuiStepLabel-label': {
    maxWidth: '100%',
    [theme.breakpoints.down('desktop')]: {
      textAlign: 'left'
    }
  }
}));

export const StyledAcceptIcon = styled(CheckCircle)(({ theme }) => ({
  color: theme.palette.success.main
}));

export const StyledPendingIcon = styled(CheckCircle)(({ theme }) => ({
  color: theme.palette.custom.neutral[300]
}));
