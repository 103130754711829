import {
  createBrowserRouter,
  createRoutesFromElements,
  Route
} from 'react-router-dom';

import routes from 'src/constants/routes';
import BlockchainAddressDocumentNumberPage from 'src/pages/blockchain-address-tauron/documentNumber';
import BlockchainAddressAccessCodePage from 'src/pages/blockchain-address-tauron/accessCode';
import BlockchainAddressDetailsPrivatePage from 'src/pages/blockchain-address-tauron/details/detailsPrivate';
import BlockchainAddressDetailsPublicPage from 'src/pages/blockchain-address-tauron/details/detailsPublic';
import BaseLayout from 'src/components/baseLayout';
import { PrivateDocumentContextProvider } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';
import { PublicDocumentContextProvider } from 'src/modules/DocumentsPrivate/contexts/PublicDocumentContext';
import TauronFeedbackPage from 'src/pages/tauron-feedback';
import Tauron404Page from 'src/pages/tauron404';
import TauronIncorrectDocumentPage from 'src/pages/tauron-incorrect-url';
import ServiceOutageTauron from 'src/components/serviceOutageTauron';
import TauronLoader from 'src/components/loader/tauronLoader';
import TauronExpiredDocumentPage from 'src/pages/tauron-document-expired';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={routes.index} element={<BaseLayout />}>
      <Route index element={<Tauron404Page />} />
      <Route path={routes.help} element={<TauronFeedbackPage />} />
      <Route
        path={routes.documentAccessType}
        element={<PrivateDocumentContextProvider />}
      >
        <Route index element={<BlockchainAddressDocumentNumberPage />} />
        <Route
          path={routes.documentAccessType2Sms}
          element={<BlockchainAddressAccessCodePage />}
        />
        <Route
          path={routes.documentAccessTypeIncorrectQuery}
          element={<TauronIncorrectDocumentPage />}
        />
      </Route>
      <Route
        path={routes.blockchainAddressDetails}
        element={<PrivateDocumentContextProvider />}
      >
        <Route index element={<BlockchainAddressDetailsPrivatePage />} />
      </Route>
      <Route
        path={routes.publicDocument}
        element={<PublicDocumentContextProvider />}
      >
        <Route
          path={routes.publicDocumentBlockchainAddress}
          element={<BlockchainAddressDetailsPublicPage />}
        />
        <Route
          path={routes.publicDocumentExpired}
          element={<TauronExpiredDocumentPage />}
        />
        <Route
          path={routes.publicDocumentIncorrectURL}
          element={<TauronIncorrectDocumentPage />}
        />
      </Route>
      <Route path="*" element={<Tauron404Page />} />
    </Route>
  )
);

const routerWithServiceOutage = createBrowserRouter(
  createRoutesFromElements(
    <Route path={routes.index} element={<BaseLayout />}>
      <Route index element={<ServiceOutageTauron />} />
      <Route path="*" element={<ServiceOutageTauron />} />
    </Route>
  )
);

const routerWithLoader = createBrowserRouter(
  createRoutesFromElements(
    <Route path={routes.index} element={<BaseLayout />}>
      <Route index element={<TauronLoader />} />
      <Route path="*" element={<TauronLoader />} />
    </Route>
  )
);
export { router, routerWithServiceOutage, routerWithLoader };
