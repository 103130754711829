import { AxiosError } from 'axios';

import ApiError from 'src/lib/apiError';
import {
  EXPIRED_DOCUMENT_ERROR_RESPONSE_STATUS_CODE,
  EXPIRED_DOCUMENT_ERROR_RESPONSE_MESSAGE,
  DOCUMENT_EXPIRED_ERROR_SUBTITLE
} from 'src/modules/DocumentsPrivate/constants/errors';
import { DocumentErrorResponseType } from 'src/modules/DocumentsPrivate/types';

type ErrorResponse = AxiosError<unknown, unknown> | unknown;

export const getDocumentExpirationFlag = (
  errorData: DocumentErrorResponseType
) =>
  errorData?.statusCode === EXPIRED_DOCUMENT_ERROR_RESPONSE_STATUS_CODE &&
  errorData?.message === EXPIRED_DOCUMENT_ERROR_RESPONSE_MESSAGE;

export const getDocumentCatalogExpiredErrorResponse = (err: ErrorResponse) => {
  const error = err as AxiosError;
  const errorData = error?.response?.data as DocumentErrorResponseType;
  let returnError = error.message;

  if (!!errorData) {
    if (getDocumentExpirationFlag(errorData)) {
      returnError = DOCUMENT_EXPIRED_ERROR_SUBTITLE;
    }
  }

  return new ApiError(returnError);
};
