/* eslint-disable unused-imports/no-unused-vars */
import { useTranslation } from 'react-i18next';
import {
  Button,
  Typography,
  SelectChangeEvent,
  useMediaQuery,
  Tab,
  Box,
  Stack
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {
  useCallback,
  useEffect,
  useState,
  SyntheticEvent,
  useMemo,
  useRef
} from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { TabContext } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { decode } from 'base64-arraybuffer';

import theme from 'src/constants/theme';
import routes from 'src/constants/routes';
import hasFeature from 'src/lib/hasFeature';
import { localeDateFormatter, localeDayFormatter } from 'src/lib/formatters';
import DefaultExpiredDocumentPage from 'src/pages/default404/documentExpired';
import { ChainLoader } from 'src/components/loader';
import DocumentScaleControll from 'src/modules/DocumentsPrivate/components/documentDetailsStep/components/DocumentControllsComponent';
import { SignatureFormProvider } from 'src/modules/DocumentsPrivate/components/documentSignatureForm/context/SignatureFormProvider';
import { ReactComponent as DownloadIcon } from 'src/assets/fileDownload.svg';
import DocumentHashTabs from 'src/modules/DocumentsPrivate/components/documentHashTabs';
import useDocumentPublisher from 'src/modules/DocumentsPrivate/hooks/useDocumentPublisher';
import usePostVerifyDocumentByBlockchainAddress from 'src/modules/DocumentsPrivate/hooks/usePostVerifyDocumentByBlockchainAddress';
import PublicDocumentHistory from 'src/modules/DocumentsPrivate/components/documentHistory/PublicDocumentHistory';
import { DocumentPublicHistoryProvider } from 'src/modules/DocumentsPrivate/contexts/DocumentPublicHistoryContext';
import usePublicDocumentBC from 'src/modules/DocumentsPrivate/hooks/usePublicDocumentBC';
import { getDocumentExpirationFlag } from 'src/modules/DocumentsPrivate/helpers/getDocumentCatalogExpiredErrorResponse';
import {
  DOCUMENT_TYPE,
  DocumentErrorResponseType
} from 'src/modules/DocumentsPrivate/types';

import { handleOnScrollDebounced } from './utils';
import {
  StyledContentWrapper,
  StyledDivider,
  StyledDocumentDetailsHeader,
  StyledDocumentDetailsWrapper,
  StyledDocumentInformation,
  StyledDocumentInformations,
  StyledDocumentInformationTitle,
  StyledDocumentInformationValue,
  StyledDocumentWrapper,
  StyledHeaderRightSide,
  StyledLoader,
  StyledPreviewContainer,
  StyledRelativeContainer,
  StyledTabList,
  StyledTabPanel,
  StyledTabWrapper,
  StyledBackButton
} from './styles';

import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

enum TabName {
  DETAILS = 'details',
  HISTORY = 'history',
  SIGNING = 'signing',
  PREVIEW = 'preview'
}

type DocumentDetailsStepType = {
  blockchainAddress: string;
};

const DocumentDetailsPublicStep = ({
  blockchainAddress
}: DocumentDetailsStepType) => {
  const inputRef = useRef<HTMLInputElement>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [documentBlob, setDocumentBlob] = useState<Blob | null>(null);
  const [documentTitle, setDocumentTitle] = useState<string>('');
  const [tabValue, setTabValue] = useState<TabName>(TabName.DETAILS);
  const [pagesTotalNumber, setPagesTotalNumber] = useState<number>(0);
  const [documentScale, setDocumentScale] = useState<number>(1.0);
  const {
    data: verificationData,
    isSuccess: isVerificationSuccess,
    isLoading: isVeryifyBlockchainAddress
  } = usePostVerifyDocumentByBlockchainAddress(
    { blockchainAddress },
    {
      enabled: !!blockchainAddress,
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  const {
    data: document,
    isLoading: isDocumentLoading,
    isError,
    error,
    failureCount
  } = usePublicDocumentBC(blockchainAddress, true);

  const isExpired = useMemo(() => {
    const errorData = error?.response?.data as DocumentErrorResponseType;
    if (isError && !!error) {
      return getDocumentExpirationFlag(errorData);
    }
    return false;
  }, [isError, error]);

  const displaySeparateViewerTab = useMediaQuery(
    theme.breakpoints.up('desktop')
  );

  const downscaleDocument = useMediaQuery(theme.breakpoints.down('tablet'));
  const onPageChange = useCallback((page: number | null) => {
    if (page === null) {
      return;
    }

    if (inputRef.current) {
      inputRef.current.value = page.toString();
    }
  }, []);
  const { data: publisher, isLoading: isLoadingPublisher } =
    useDocumentPublisher(
      {
        publisherId: verificationData?.publisherId || ''
      },
      {
        retry: false,
        enabled: isVerificationSuccess,
        refetchOnWindowFocus: false
      }
    );

  const publicationDate = useMemo(
    () => document?.publicationDate || '',
    [document]
  );

  const publisherId = useMemo(() => document?.publisherId || '', [document]);

  useEffect(() => {
    if (document?.sourceDocument && blockchainAddress) {
      setDocumentBlob(
        new Blob([new Uint8Array(decode(document?.sourceDocument)).buffer], {
          type: `application/pdf`
        })
      );
      setDocumentTitle(document?.title);
    }
  }, [document, blockchainAddress]);

  useEffect(() => {
    if (downscaleDocument) {
      setDocumentScale(0.5);
    }
  }, [downscaleDocument]);

  useEffect(() => {
    if (displaySeparateViewerTab && tabValue === TabName.PREVIEW) {
      setTabValue(TabName.DETAILS);
    }
  }, [displaySeparateViewerTab, tabValue]);

  const handleDownloadClick = useCallback(() => {
    if (!documentBlob) {
      return;
    }

    const link = window.document.createElement('a');

    link.href = URL.createObjectURL(documentBlob);
    link.download = documentTitle;
    window.document.body.append(link);
    link.click();
    link.remove();

    setTimeout(() => URL.revokeObjectURL(link.href));
  }, [documentBlob, documentTitle]);

  const handleDocumentSuccessLoad = useCallback(
    ({ numPages }: { numPages: number }) => setPagesTotalNumber(numPages),
    []
  );

  const handleScaleChange = useCallback(
    (event: SelectChangeEvent<number>) =>
      setDocumentScale(event.target.value as number),
    []
  );

  const handleTabChange = useCallback(
    (_event: SyntheticEvent, newValue: TabName) => setTabValue(newValue),
    []
  );

  const handleBack = () => {
    navigate(routes.publicDocumentList);
  };

  if (!blockchainAddress) {
    navigate(routes.publicDocumentIncorrectURL);
  }

  const isLoading = isDocumentLoading || isLoadingPublisher;

  const BackButton = () => (
    <Box>
      <Typography>
        <StyledBackButton
          onClick={handleBack}
          startIcon={<KeyboardArrowLeftIcon viewBox="6 2 24 24" />}
          color="secondary"
        >
          {t('DOCUMENT_DETAILS_GO_BACK')}
        </StyledBackButton>
      </Typography>
    </Box>
  );

  if (isLoading && !isExpired) {
    return (
      <Stack>
        <BackButton />
        <StyledLoader sx={{ marginTop: theme.spacing(12) }}>
          <ChainLoader />
        </StyledLoader>
      </Stack>
    );
  }

  if (isExpired) {
    return (
      <Stack>
        <BackButton />
        <DefaultExpiredDocumentPage />
      </Stack>
    );
  }

  return (
    <>
      <DocumentPublicHistoryProvider
        blockchainAddress={blockchainAddress}
        publisherId={publisherId}
        documentType={DOCUMENT_TYPE.PUBLIC}
      >
        <BackButton />
        <StyledDocumentDetailsHeader>
          <Typography maxWidth="50%" noWrap variant="h6" fontWeight={600}>
            {documentTitle}
          </Typography>
          <StyledHeaderRightSide>
            {displaySeparateViewerTab && (
              <DocumentScaleControll
                t={t('PRIVATE_DOCUMENT_PREVIEW_PAGE')}
                inputRef={inputRef}
                max={pagesTotalNumber}
                value={documentScale}
                handleScaleChange={handleScaleChange}
              />
            )}
            <Button
              variant="contained"
              onClick={handleDownloadClick}
              startIcon={<DownloadIcon />}
            >
              {t(
                'PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_DOWNLOAD_DOCUMENT_BUTTON'
              )}
            </Button>
          </StyledHeaderRightSide>
        </StyledDocumentDetailsHeader>
        <SignatureFormProvider>
          <StyledContentWrapper>
            <StyledTabWrapper>
              <StyledDocumentDetailsWrapper>
                <TabContext value={tabValue}>
                  <StyledTabList onChange={handleTabChange}>
                    {!displaySeparateViewerTab && (
                      <Tab
                        label={t('PRIVATE_DOCUMENTS_LIST_PREVIEW_COLUMN')}
                        value={TabName.PREVIEW}
                      />
                    )}
                    <Tab
                      label={t('PRIVATE_DOCUMENT_DETAILS')}
                      value={TabName.DETAILS}
                    />
                    <Tab
                      label={t('PRIVATE_DOCUMENT_DETAILS_DOCUMENT_HISTORY')}
                      value={TabName.HISTORY}
                    />
                  </StyledTabList>
                  <StyledDivider />
                  <StyledTabPanel value={TabName.DETAILS}>
                    <Typography
                      variant="subtitle1"
                      marginBottom="27px"
                      fontWeight={600}
                      sx={{ overflowWrap: 'break-word' }}
                    >
                      {documentTitle}
                    </Typography>
                    {isVerificationSuccess && (
                      <DocumentHashTabs document={verificationData} />
                    )}
                    <StyledDocumentInformations>
                      <StyledDocumentInformation>
                        <StyledDocumentInformationTitle
                          variant="body2"
                          fontWeight={600}
                        >
                          {`${t(
                            hasFeature('diploma')
                              ? 'PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_DATE_OF_ISSUE'
                              : 'PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_PUBLICATION_DATE'
                          )}: `}
                        </StyledDocumentInformationTitle>
                        <StyledDocumentInformationValue variant="body2">
                          {localeDateFormatter(publicationDate)}
                        </StyledDocumentInformationValue>
                      </StyledDocumentInformation>
                      <StyledDocumentInformation>
                        <StyledDocumentInformationTitle
                          variant="body2"
                          fontWeight={600}
                        >
                          {`${t('PRIVATE_DOCUMENT_VERSION')}: `}
                        </StyledDocumentInformationTitle>
                        <StyledDocumentInformationValue variant="body2">
                          {document?.versionName}
                        </StyledDocumentInformationValue>
                      </StyledDocumentInformation>
                      <StyledDocumentInformation>
                        <StyledDocumentInformationTitle
                          variant="body2"
                          fontWeight={600}
                        >
                          {`${t(
                            'PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_PUBLICATION_CATEGORY'
                          )}: `}
                        </StyledDocumentInformationTitle>
                        <StyledDocumentInformationValue variant="body2">
                          {document?.category}
                        </StyledDocumentInformationValue>
                      </StyledDocumentInformation>
                      <StyledDocumentInformation>
                        <StyledDocumentInformationTitle
                          variant="body2"
                          fontWeight={600}
                        >
                          {`${t(
                            'PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_PUBLISHER'
                          )}: `}
                        </StyledDocumentInformationTitle>
                        <StyledDocumentInformationValue variant="body2">
                          {publisher?.name}
                        </StyledDocumentInformationValue>
                      </StyledDocumentInformation>
                      {!!document && (
                        <>
                          <StyledDocumentInformation>
                            <StyledDocumentInformationTitle
                              variant="body2"
                              fontWeight={600}
                            >
                              {`${t('DOCUMENT_LIST_VALID_SINCE_COLUMN')}: `}
                            </StyledDocumentInformationTitle>
                            <StyledDocumentInformationValue variant="body2">
                              {localeDayFormatter(document.validSince)}
                            </StyledDocumentInformationValue>
                          </StyledDocumentInformation>
                          <StyledDocumentInformation>
                            <StyledDocumentInformationTitle
                              variant="body2"
                              fontWeight={600}
                            >
                              {`${t('DOCUMENT_LIST_VALID_UNTIL_COLUMN')}: `}
                            </StyledDocumentInformationTitle>
                            <StyledDocumentInformationValue variant="body2">
                              {localeDayFormatter(document.validUntil)}
                            </StyledDocumentInformationValue>
                          </StyledDocumentInformation>
                        </>
                      )}
                    </StyledDocumentInformations>
                  </StyledTabPanel>
                  <StyledTabPanel value={TabName.HISTORY}>
                    <PublicDocumentHistory />
                  </StyledTabPanel>
                  <StyledTabPanel sx={{ marginTop: 0 }} value={TabName.PREVIEW}>
                    <StyledPreviewContainer>
                      <DocumentScaleControll
                        t={t('PRIVATE_DOCUMENT_PREVIEW_PAGE')}
                        inputRef={inputRef}
                        max={pagesTotalNumber}
                        value={documentScale}
                        handleScaleChange={handleScaleChange}
                      />
                      <StyledRelativeContainer>
                        <StyledDocumentWrapper
                          id="pdf-preview"
                          onScroll={() => handleOnScrollDebounced(onPageChange)}
                        >
                          {documentBlob && (
                            <Document
                              file={documentBlob}
                              onLoadSuccess={handleDocumentSuccessLoad}
                            >
                              {[...Array(pagesTotalNumber)].map(
                                (_el, index) => (
                                  <Page
                                    key={`page-${index}`}
                                    pageIndex={index}
                                    scale={documentScale}
                                  />
                                )
                              )}
                            </Document>
                          )}
                        </StyledDocumentWrapper>
                      </StyledRelativeContainer>
                    </StyledPreviewContainer>
                  </StyledTabPanel>
                </TabContext>
              </StyledDocumentDetailsWrapper>
            </StyledTabWrapper>
            {displaySeparateViewerTab && (
              <StyledRelativeContainer>
                <StyledDocumentWrapper
                  id="pdf-preview"
                  onScroll={() => handleOnScrollDebounced(onPageChange)}
                >
                  {documentBlob && (
                    <Document
                      file={documentBlob}
                      onLoadSuccess={handleDocumentSuccessLoad}
                    >
                      {[...Array(pagesTotalNumber)].map((_el, index) => (
                        <Page
                          key={`page-${index}`}
                          pageIndex={index}
                          scale={documentScale}
                        />
                      ))}
                    </Document>
                  )}
                </StyledDocumentWrapper>
              </StyledRelativeContainer>
            )}
          </StyledContentWrapper>
        </SignatureFormProvider>
      </DocumentPublicHistoryProvider>
    </>
  );
};

export default DocumentDetailsPublicStep;
